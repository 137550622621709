import apiurl from '@/util/apiurl'
import * as moment from 'moment'
const ITEM = apiurl.WS_KONSULTASI;
export default {
    namespaced: true,
    state: {
        interior: {},
        items: [],
        filtered: {
            category: "",
            per_page: 12,
            page: 1,
        },
    },
    getters: {
        interior: (state) => state.interior,
        items: (state) => state.items,
        filtered: (state) => state.filtered,
    },
    mutations: {
        setInterior: (state, interior) => (state.interior = interior),
        setItems: (state, items) => (state.items = items),
        setFiltered: (state, filteredItems) => {
            _.forEach(filteredItems, function (value, key) {
                state.filtered[key] = value;
            });
        },
        resetFilter: (state, withQ = false) => {
            if (withQ) state.filtered.category = "";
            state.filtered.per_page = 12,
            state.filtered.page = 1;
        },
        setCopiedFilter: (state, copiedFilter) => (state.copiedFilter = copiedFilter),

    },
    actions: {
        async fetchItems({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if(state.filtered.page == 1 ) commit("setItems", []);
            await ITEM.GET_CUSTOMER(state.filtered).then(response => {
                result = response.data;
                let items = result.data.results;
                if (state.filtered.page > 1) {
                    items = state.items;
                    items.push(...result.data.results);
                }
                commit("setItems", items);
            }).catch(error => {
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async fetchDetail({ commit, state }, slug) {
            let result = { success: false };
            await ITEM.GET_DETAIL_CUSTOMER(slug).then(response => {
                result = response.data;
                
                commit("setInterior", result.data);
                // console.log(result);
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
    },
};
