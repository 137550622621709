import list from "@/store/product/list";
import detail from "@/store/product/detail";
import bundle from "@/store/product/bundle";
export default {
    namespaced:true,
    modules: {
        list,
        detail,
        bundle,
    },
};
