import apiurl from '@/util/apiurl';
const URL = apiurl.WS_NEWS;

export default {
    namespaced:true,
    state:{
        article: {},
        tags: [],
        tagsTop: [],
        products: [],
        categories: [],
        articleSelected: [],
        articleSlideshow: [],
        articleEditor: [],
    },
    getters:{
        article: (state) => state.article,
        tags: (state) => state.tags,
        tagsTop: (state) => state.tagsTop,
        products: (state) => state.products,
        categories: (state) => state.categories,
        articleSelected: (state) => state.articleSelected,
        articleSlideshow: (state) => state.articleSlideshow,
        articleEditor: (state) => state.articleEditor,
    },
    mutations:{
        setArticle: (state, article) => (state.article = article),
        setTags: (state, data) => (state.tags = data),
        setTagsTop: (state, data) => (state.tagsTop = data),
        setProducts: (state, data) => (state.products = data),
        setCategories: (state, data) => (state.categories = data),
        setArticleSelected: (state, data) => (state.articleSelected = data),
        setArticleSlideshow: (state, data) => (state.articleSlideshow = data),
        setArticleEditor: (state, data) => (state.articleEditor = data),
    },
    actions:{
        async getTags({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (state.tags.length < 1) {
                await URL.GET_TAGS().then(response => {
                    result = response.data;
                    // console.log(result);
                    commit("setTags", result);
                }).catch(error => {
                    // console.error("getTags", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async getTagsTop({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (state.tags.length < 1) {
                await URL.GET_TAGS_TOP().then(response => {
                    result = response.data;
                    commit("setTagsTop", result);
                }).catch(error => {
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async getCategories({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (state.tags.length < 1) {
                await URL.GET_CATEGORIES().then(response => {
                    result = response.data;
                    commit("setCategories", result);
                }).catch(error => {
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async getArticleSelected({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            
            await URL.GET_SELECTED({'param': 'selected_article'}).then(response => {
                result = response.data;
                commit("setArticleSelected", result.data);
            }).catch(error => {
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async getArticleSlideshow({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            
            await URL.GET_SELECTED({'param': 'slider_article'}).then(response => {
                result = response.data;
                commit("setArticleSlideshow", result.data);
            }).catch(error => {
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async getArticleEditor({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            
            await URL.GET_SELECTED({'param': 'editor_pick'}).then(response => {
                result = response.data;
                commit("setArticleEditor", result.data);
            }).catch(error => {
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async fetchData({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_LIST(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
        async fetchDetail({ commit, state }, slug) {
            let result = { success: false };
            await URL.GET_DETAIL(slug).then(response => {
                result = response.data;
                
                commit("setArticle", result.data);
                // console.log(result);
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
        async fetchProduct({ commit, state }, news_id) {
            let result = { success: false };
            await URL.GET_PRODUCT({'news_id': news_id}).then(response => {
                result = response.data;
                commit("setProducts", result.data);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
    }
}