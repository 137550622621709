<template>
  <div style="overflow:hidden;">
      <div v-if="loading" class="ol-home-inspo2" style="height:300px;padding-top: 20px; margin-bottom: 20px;">
          <SkeletonLoader />
      </div>
      <section v-else class="ol-home-inspo2" style="padding-top: 20px; margin-bottom: 20px;">
            <!-- <div class="heading-inspo-container">
                <h2 class="heading"><span>Inspirasi Buat Kamu</span> </h2>
            </div> -->
            <h2 class="ol-section-title">
                  <span>Inspirasi Buat Kamu</span>
                  <i></i>
              </h2>
            <div class="ol-home-inspo2-list">
                <div class="ol-home-inspo2-list-slide" v-for="(item, i) in inspirasi" :key="i">
                    <a class="ol-home-inspo2-list-item" :href="'/inspirasi/'+item.slug">
                        <img :src="item.image_url" :alt="item.meta_title">
                    </a>
                </div>
            </div>
            <div class="inspo-lain">
                <div class="inspo-lain-btn">
                    <router-link class="white dftbtn" :to="'/inspirasi'">
                        <p><span class="bg"></span><span class="base"></span><span class="text">Temukan Inspirasi
                                Lainnya</span>
                        </p>
                    </router-link>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import {mapActions,mapGetters} from "vuex";

export default {
  name: "Inspirasi",
  components:{
      SkeletonLoader
  },
  data() {
    return {
      loading:true
    };
  },
  computed:{
    ...mapGetters("home",["inspirasi"]),
  },
  methods:{
    ...mapActions('home',['getInspirasi']),
    async _getInspirasi(){
      await this.getInspirasi();
      this.loading = false;
    }
  },
  mounted() {
    this._getInspirasi();
  },
  updated() {
    $(".ol-home-inspo2-list").slick({
          slidesToScroll: 1,
          slidesToShow: 1,
          swipeToSlide: true,
          dots: !1,
          autoplay: !0,
          autoplaySpeed: 5e3,
          arrows: !0,
          pauseOnFocus: !1,
          infinite: !0,
          speed: 800,
          fade: !1,
          centerMode: true,
          variableWidth: true,
          useTransform: true,
          cssEase: 'cubic-bezier(0.380, -0.100, 0.390, 1.235)',
      })
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/css/_inspiration.css";
@import "../../assets/js/slick/slick.css";
</style>
