import apiurl from '@/util/apiurl'
const URL = apiurl.WS_PROFILE.BANK_ACCOUNT;

export default {
    namespaced: true,
    state: {
        banks: [],
        numbers:[],
    },
    getters: {
        banks: (state) => state.banks,
        numbers: (state) => state.numbers,
    },
    mutations: {
        setBanks: (state, banks) => (state.banks = banks),
        setNumbers: (state, numbers) => (state.numbers = numbers),
    },
    actions: {
        async fetchBanks({ commit, state }) {
            let status = true;
            if (!state.banks || state.banks.length < 1) {
                await URL.BANKS().then(response => {
                    commit("setBanks", response.data.data);
                    // console.log("fetchBanks",response.data.data);
                }).catch(error => {
                    status = false;
                    // console.log("fetchBanks", error);
                });
            }
            return status;
        },
        async fetchNumbers({ commit, state }) {
            let status = true;
            if (!state.banks || state.banks.length < 1) {
                await URL.GET().then(response => {
                    commit("setNumbers", response.data.data);
                    // console.log("fetchNumbers",response.data.data);
                }).catch(error => {
                    status = false;
                    // console.log("fetchNumbers", error);
                });
            }
            return status;
        },
        async saveNumber({ commit, state }, input) {
            let status = false;
            
            await URL.STORE(input).then(response => {
                let data = response.data.data;
                // data.icon_url = _.find(state.banks, { id: parseInt(data.uni_bank_id) }).icon_url;
                
                let numbers = state.numbers;
                numbers.push(data);
                commit("setNumbers", numbers);
                // console.log("saveNumber", response.data.data);
                status = true;
            }).catch(error => {
                // console.log("saveNumber", error);
            });
            return status;
        },
        async updateNumber({ commit, state }, input) {
            let status = false;
            
            await URL.UPDATE(input).then(response => {
                let data = response.data.data;
                // data.icon_url = _.find(state.banks, { id: parseInt(data.uni_bank_id) }).icon_url;
                
                let numbers = state.numbers;
                numbers[_.findIndex(numbers,{"id":data.id})] = data;
                commit("setNumbers", numbers);
                status = true;
                // console.log("updateNumber", response.data.data);
            }).catch(error => {
                // console.log("updateNumber", error);
            });
            return status;
        },
        async delNumber({ commit, state }, id) {
            let status = false;
            await URL.DELETE({ id:id }).then(response => {
                let data = response.data.data;
                commit("setNumbers", _.filter(state.numbers, (item) => item.id != data.id));
                status = true;
                // console.log("delNumber", response.data.data);
            }).catch(error => {
                // console.log("delNumber", error);
            });

            return status;
        },
    }
};