<template>
    <div class="ol-home-flash-list">
        <div  v-for="(product, index) in products" :key="index">
            <router-link  :to="product.r_uni_products.bundling == 1 ? '/bundle/'+product.r_uni_products.slug : '/product/'+product.r_uni_products.slug"  :title="product.r_uni_product_variants.name_short">
                <div class="ol-home-flash-list__item" style="width:100%; padding:4px">
                    <div class="more-product-item">
                        <figure v-if="product.r_uni_product_variant_images" class="ol-home-product__content-item-list--item-figure">
                            <div :v-if="product.r_uni_product_variant_images.length >= 1"   v-for="(image,i) in product.r_uni_product_variant_images.slice(0, 3)" :key="image.id" :class="'ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--'+(i+1)">
                                <img :src="image.image_url" :alt="image.alt_text" :title="product.r_uni_products.name">
                            </div>
                            
                            <div v-if="product.r_uni_product_variant_images.length < 1"  class="ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--">
                                <img :src="noImage()">
                            </div>
                        </figure>
                        <h3 v-if="product.r_uni_products" class="ol-home-product__content-item-list--item-title">
                            {{product.r_uni_products.name}}
                        </h3>
                        <div v-if="product.r_uni_products" class="ol-home-product__content-item-list--item-price">
                            <span class="ol-home-product__content-item-list--item-price-real">{{product.r_uni_product_variant_prices.ho_normal_rp}}</span>
                            <small class="ol-home-product__content-item-list--item-price-discount">{{product.price_rp}} </small>
                        </div>
                        <div v-if="product.r_uni_products" class="ol-home-flash-list__item-bar flashSaleStock">
                            <span>
                            <i class="currentStock" :style="'width:'+((product.remains/product.limit)*100)+'%'"></i>
                            <div class="stockText">
                                {{ product.remains == 0 ? 'Habis' : product.remains == 1 ? 'Hampir Habis' :  'Stok Terbatas' }}
                                <!-- Tersedia {{product.remains }}  -->
                                <!-- <i class="fas fa-fire-alt stockIcon"></i> -->
                            </div>
                            </span>
                        </div>
                        <span v-if="product.discount" class="ol-home-product__content-item-list--item-discount" style="top:4px">{{product.discount}}<br>OFF</span>

                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name:"ProductFlash",
    props:["products"],
}
</script>

<style>

</style>