import apiurl from '@/util/apiurl';
const URL = apiurl.WS_MOREVERSE;

export default {
    namespaced: true,
    state: {
        mitra: [],
        catalogue: [],
        video: [],
        activity: [],
        exhibition: 'https://www.coohom.com/pub/tool/panorama/aiwalking?obsPlanId=3FO44QIQY3PQ&locale=en_US',
        filteredProducts: {
            q: "",
            per_page: 12,
            page: 1,
            is_bundle: 0,
        },
        notifications:[],
    },
    getters: {
        mitra: (state) => state.mitra,
        catalogue: (state) => state.catalogue,
        video: (state) => state.video,
        activity: (state) => state.activity,
        exhibition: (state) => state.exhibition,
        notifications: (state) => state.notifications,
    },
    mutations: {
        setMitra: (state, data) => (state.mitra = data),
        setCatalogue: (state, data) => (state.catalogue = data),
        setVideo: (state, data) => (state.video = data),
        setActivity: (state, data) => (state.activity = data),
        setExhibition: (state, data) => (state.exhibition = data),
        setNotifications: (state, data) => (state.notifications = data),
    },
    actions: {
        async fetchMitra({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_MITRA(data).then(response => {
                result = response.data;
                let mitra = result.data.results;
                commit("setMitra", mitra);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async fetchCatalogue({ commit, state }) {
            let result = { success: false };
            await URL.GET_CATALOGUE().then(response => {
                result = response.data;
                let catalogue = result.data;
                commit("setCatalogue", catalogue);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async fetchVideo({ commit, state }) {
            let result = { success: false };
            await URL.GET_VIDEO().then(response => {
                result = response.data;
                let video = result.data;
                commit("setVideo", video);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async fetchActivity({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_ACTIVITY(data).then(response => {
                result = response.data;
                let activity = result.data;
                commit("setActivity", activity);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async fetchExhibition({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_EXHIBITION(data).then(response => {
                result = response.data;
                let exhibition = result.data;
                commit("setExhibition", exhibition);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async fetchNotifications({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_NOTIFICATIONS(data).then(response => {
                result = response.data;
                let notifications = result.data;
                commit("setNotifications", notifications);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },

        async setReminder({ commit, state }, activity_id) {
            let result = { success: false };
            await URL.SET_REMINDER(activity_id).then(response => {
                result = response.data;
                console.log(result.data);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        

    }
}