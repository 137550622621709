import apiurl from '@/util/apiurl'
import helpme from '@/util/helpme'
import Vue from 'vue';
const PRODUCT = apiurl.WS_PRODUCT;
const CART = apiurl.WS_TRANSACTION.CART;
export default {
    namespaced:true,
    state: {
        product: {},
        activeBundle: null,
        list: null,
        selectedVariants: {},
        productsSummary:[],
        rating: null,
        reviews: [],
        others:[],
        reviewsInfo: {
            page:1,
            per_page: 3,
            product_id:0,
            total: 0,
            total_pages: 0,
            rating:null,
        },
        location: null,
        collection: !JSON.parse(sessionStorage.getItem("collection")) ? [] : JSON.parse(sessionStorage.getItem("collection")),
    },
    getters: {
        product: (state) => state.product,
        list: (state) => state.list,
        activeBundle: (state) => state.activeBundle,
        selectedVariants: (state) => state.selectedVariants,
        productsSummary: (state) => state.productsSummary,
        rating: (state) => state.rating,
        reviews: (state) => state.reviews,
        others: (state) => state.others,
        location: (state) => state.location,
        reviewsInfo: (state) => state.reviewsInfo,
        collection: (state) => state.collection,
    },
    mutations: {
        setProduct: (state, product) => {
            state.product = product;
            if (!_.find(state.collection, { id: product.id })) {
                state.collection.push(product);
                sessionStorage.setItem("collection", JSON.stringify(state.collection));
            }

        },
        
        setOthers: (state, others) => (state.others = others),
        setActiveBundle: (state, payload) => {
            if (payload) {
                payload.r_uni_product_variant_prices.saving_nominal = _.toNumber(payload.r_uni_product_variant_prices.ho_more) - _.toNumber(payload.r_uni_product_variant_prices.more_discount_price);
                payload.r_uni_product_variant_prices.saving_rp = helpme.toRp(payload.r_uni_product_variant_prices.saving_nominal);
                state.activeBundle = payload;
                state.list = state.activeBundle.r_uni_product_variant_bundles;
            }
        },
        setSelectedVariants: (state, payload) => {
            let code = payload.bundle.id.toString() +"-"+ payload.index.toString() + "-unique-code";
            let variant = null;
            if (payload.uni_product_variants_id > 0) {
                variant = _.find(payload.bundle.r_uni_product_variants, { id: payload.uni_product_variants_id });
                variant.bundle_id = payload.bundle.id;
                variant.bundle_code = code;
                variant.isOutStock = payload.bundle.isOutStock;
            }
            let details = state.selectedVariants;
            if (!details[code]) details[code] = {};
            details[code] = variant;
            state.selectedVariants = details;

            //MAPING productsSummary
            let products = [];   
            for (const key in state.selectedVariants) {
                if(state.selectedVariants[key]) products.push(state.selectedVariants[key]);
            }
            let result = _.uniqBy(products, 'id');
            state.productsSummary = _.map(result, (item) => {
                item.uni_product_variants_id = item.id;
                item.quantity = _.filter(products, { id: item.id }).length;
                item.initial_quantity = item.quantity;
                return item;
            })
            state.activeBundle.bundle_summary = state.productsSummary;
        },
        setSelectedColor: (state, selectedColor) => (state.selectedColor = selectedColor),
        setReviews: (state, reviews) => (state.reviews = reviews),
        setReviewsInfo: (state, reviewsInfo) => {
            _.forEach(reviewsInfo, function (value, key) {
                state.reviewsInfo[key] = value;
            });
        },
    },
    actions: {
        async fetchProduct({ commit, state }, slug) {
            commit("setActiveBundle", null);
            commit("setSelectedColor", null);
            let result = { success: false };
            let existed = _.find(state.collection, { slug: slug });
            if (!existed){
                await PRODUCT.BUNDLE(slug).then(response => {
                    result = response.data;
                    commit("setProduct", result.data);
                }).catch(error => {
                    // console.error("fetchProduct", error);
                    result = error.response.data;
                });
            } else {
                commit("setProduct", existed);
                result.success = true;
            }
            commit("synchProductHistory");
            return result;
        },

        async fetchReviews({ commit, state }, payload) {
            commit("setReviewsInfo",payload);
            let result = { success: false };
            let data = state.reviewsInfo;
            await PRODUCT.REVIEWS(data).then(response => {
                result = response.data;
                commit("setReviews", result.data.results);
                commit("setReviewsInfo", {
                    total: result.data.total,
                    total_pages: Math.ceil(result.data.total/parseInt(data.per_page)),
                });
            }).catch(error => {
                // console.error("fetchReviews", error);
                result = error.response.data;
            });
            return result;
        },

        async checkStock({ commit, state }, payload) {
            let result = { success: false, data:{} };
            await CART.CHECK_STOCK(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("checkStock", error);
                result = error.response.data;
            });
            return result;
        },

        async fetchOthers({ commit, state }) {
            let result = { success: false };
            if (_.isEmpty(state.others)) {
                await PRODUCT.OTHERS_BUNDLE(state.product.id).then(response => {
                    result = response.data;
                    commit("setOthers", result.data.results);
                }).catch(error => {
                    // console.error("fetchOthers", error);
                    result = error.response.data;
                });
            }
            return result;
        },
    },
};
