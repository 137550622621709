import apiurl from '@/util/apiurl'
const URL = apiurl.WS_PROFILE.PRODUCT;
const HOME = apiurl.WS_HOME;
export default {
    namespaced: true,
    state: {
        lastSeen: [],
        lastPaket : [],
        reviewTemplates:[],
        complainTemplates:[],
    },
    getters: {
        lastSeen: (state) => state.lastSeen,
        lastPaket: (state) => state.lastPaket,
        reviewTemplates: (state) => state.reviewTemplates,
        complainTemplates: (state) => state.complainTemplates,
    },
    mutations: {
        setReviewTemplates: (state, payload) => (state.reviewTemplates = payload),
        setComplainTemplates: (state, payload) => (state.complainTemplates = payload),
        setLastSeen: (state, payload) => (state.lastSeen = payload),
        setLastPaket: (state, payload) => (state.lastPaket = payload),
    },
    actions: {
        async saveProductHistory({ getters }) {
            let result = { success: false };
            let history = !JSON.parse(sessionStorage.getItem("collection")) ? [] : JSON.parse(sessionStorage.getItem("collection"));
            if (history.length > 0) {
                let data = { products_id: _.map(history, "id") };
                await URL.SYNC_HISTORY(data).then(response => {
                    result = response.data;
                }).catch(error => {
                    // console.error("saveProductHistory", error);
                    result = error.response.data;
                });
                return result;
            }
        },

        async fetchLastSeen({ commit }) {
            let result = { success: false };
            await URL.LAST_SEEN().then(response => {
                result = response.data;
                commit("setLastSeen", result.data.results);
            }).catch(error => {
                // console.error("fetchLastSeen", error);
                result = error.response.data;
            });
            return result;
        },

        async fetchLastPaket({ commit }) {
            let result = { success: false };
            await URL.LIST_PAKET().then(response => {
                result = response.data;
                commit("setLastPaket", result.data.results);
            }).catch(error => {
                // console.error("fetchLastPaket", error);
                result = error.response.data;
            });
            return result;
        },

        async fetchReviewTemplates({state, commit }) {
            let result = { success: false };
            if (_.isEmpty(state.reviewTemplates)) {
                await URL.REVIEW_TEMPLATES().then(response => {
                    result = response.data;
                    commit("setReviewTemplates", result.data);
                }).catch(error => {
                    // console.error("fetchReviewTemplates", error);
                    result = error.response.data;
                });
            }
            return result;
        },

        async saveReview({state, commit },data) {
            let result = { success: false };
            await URL.DO_REVIEW(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("saveReview", error);
                result = error.response.data;
            });
            return result;
        },

        async deleteReviewImage({state, commit },data) {
            let result = { success: false };
            await URL.REVIEW_IMAGE_DELETE(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("deleteReviewImage", error);
                result = error.response.data;
            });
            return result;
        },

        async fetchComplainTemplates({state, commit }) {
            let result = { success: false };
            if (_.isEmpty(state.complainTemplates)) {
                await URL.COMPLAIN_TEMPLATES().then(response => {
                    result = response.data;
                    commit("setComplainTemplates", result.data);
                }).catch(error => {
                    // console.error("fetchComplainTemplates", error);
                    result = error.response.data;
                });
            }
            return result;
        },

        async saveComplain({state, commit },data) {
            let result = { success: false };
            await URL.DO_COMPLAIN(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("saveComplain", error);
                result = error.response.data;
            });
            return result;
        },

        async saveComplainImage({state, commit },data) {
            let result = { success: false };
            await URL.COMPLAIN_IMAGE_MULTI_UPLOAD(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("saveComplainImage", error);
                result = error.response.data;
            });
            return result;
        },
    }
}