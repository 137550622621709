import apiurl from '@/util/apiurl';
const URL = apiurl.WS_CHATS;;

export default {
    namespaced:true,
    state:{
        data:[],
        inbox:0
    },
    getters:{
        data: (state) => state.data,
        inbox: (state) => state.inbox
    },
    mutations:{
        setData: (state, data) => (state.data = data),
        setInbox: (state, inbox) => (state.inbox = inbox),
    },
    actions:{
        async fetchData({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_CHATS(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
        async saveChat({ commit, state }, data) {
            let result = { success: false };
            await URL.SAVE(data).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("saveData", error);
                result = error.response.data;
            });
            return result;
        },
        async getInbox({ commit, state }) {
            let result = { success: false };
            await URL.GET_INBOX().then(response => {
                result = response.data;
                commit("setInbox", result.data);
            }).catch(error => {
                // console.error("saveData", error);
                result = error.response.data;
            });
            return result;
        },

        async read({ commit, state }, payload = null) {
            let result = { success: false };
            await URL.SET_READ(payload).then(response => {
                result = response.data;
                commit("setInbox", 0);
            }).catch(error => {
                // console.error("saveData", error);
                result = error.response.data;
            });
            return result;
        },
    }
}