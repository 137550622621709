<template>
  
    <div class="ol-wrapper ol-wrapper--large">
      <div class="ol-home-menu-list" :v-if="banners.length > 0">
        <div class="ol-home-menu-list-slide" v-for="(item, index) in banners" :key="index" >
          <a :href="item.link_banner ? item.link_banner : '#'">
          <!-- <a :href="item.link_banner && clickable ? item.link_banner : '#'"> -->
            <div class="ol-home-menu-list-item">
              <img :srcset="item.mobile" />
            </div>
          </a>
        </div>
      </div>
    </div>
  
</template>

<script>
export default {
  name: "BannerTop",
  props: {
    "banners": Array,
    "clickable": Boolean
  },
  methods: {
    jq() {
      // $('.ol-home-menu-list').slick('unslick');
      $(".ol-home-menu-list").not('.slick-initialized').slick({
        dots: true,
        centerMode: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5e3,
        arrows: true,
        pauseOnFocus: !1,
        speed: 500,
        fade: !1,
        responsive: [{
          breakpoint: 550,
          settings: {
            centerMode: false,
            slidesToShow: 1,
          },
        }],
      });
    },
  },
  mounted: function() {
    this.jq();
  },
  updated: function() {
    // this.$nextTick(function() {
    //   this.jq();
    // });
  },
};
</script>

<style lang="css">
.slick-dots {
	position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li.slick-active,
.slick-dots li:hover {
	background-color: #364b7b
}
</style>
