import apiurl from '@/util/apiurl'
const URL = apiurl.WS_PROFILE.ORDER;

export default {
    namespaced: true,
    state: {
        orders:[],
        ordersInfo: {
            total: 0,
            total_pages: 0,
            page_active:1,
        },
    },
    getters: {
        orders: (state) => state.orders,
        ordersInfo: (state) => state.ordersInfo,
        
    },
    mutations: {
        setOrders: (state, payload) => (state.orders = payload),
        setOrdersInfo: (state, payload) => (state.ordersInfo = payload),
    },
    actions: {
        async fetchOrders({ commit, state }, payload) {
            let result = { success: false };
            await URL.GET(payload).then(response => {
                result = response.data;
                commit("setOrders", result.data.results);
                commit("setOrdersInfo", {
                    total:result.data.total,
                    total_pages: result.data.total_pages,
                    page_active: payload.page,
                });
            }).catch(error => {
                // console.log("fetchOrders", error);
                result = error.response.data;
            });
            return result;
        },
    }
}