<template>
    
    <section class="ol-home-flash" v-if="flashSaleAktif">
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-24"></div>
            <!-- <div class="flashsaleoverview"><h2 class="ol-section-title"><span>FLASH <strong>SALE</strong></span><i></i></h2></div> -->
            <div class="row">
                <div class="col-6">
                    <img :src="require('../../assets/images/flashsale.png')" height="30">
                </div>
                <!-- <div class="col-6">
                    {{label}} dalam:
                    <ul class="countdownFlash" id="countdownFlash">
                    <p>
                        <i class="fas fa-clock ol-fa"></i>
                    </p>
                    <small>  </small>
                    <div>
                        <span class="seconds">
                        {{ formatedCountdown || 'countdown over' }}
                        </span>
                        
                    </div>
                    </ul>
                </div> -->
            </div>
            <div class="ol-home-flash-content flashContentBorder" style="margin-top:-24px;">
                <ProductFlash :products="flashSaleAktif.r_more_promotion_flashsale_products" />
            </div>
        </div>
    </section>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.18.2/TweenMax.min.js"></script>
<script>
import  moment from '@/lib/moment';
import  ProductFlash from '@/components/product/ProductFlash.vue';
import {mapActions,mapGetters} from "vuex";

export default {
    name:"Flashsale",
    components:{
        ProductFlash
    },
    data(){
        return {
            loading:true,
            label:'',
            countdown: 0, // 5min
        }
    },
    computed: {
        ...mapGetters("home",["flashSaleAktif"]),
        formatedCountdown() {
            var v = moment.utc(moment.duration(this.countdown,"s").asMilliseconds()).format("HH:mm:ss")
        return v;
        },
    },
    methods:{
        ...mapActions('home',['getFlashSale']),
        jqSlickFlashsale(){
            $(".ol-home-flash-list").not('.slick-initialized').slick({
                slidesToScroll: 1,
                slidesToShow: 5,
                dots: !1,
                autoplay: !0,
                autoplaySpeed: 5e3,
                arrows: !0,
                pauseOnFocus: !1,
                infinite: !0,
                speed: 500,
                fade: !1,
                responsive: [{
                    breakpoint: 891,
                    settings: {
                        slidesToShow: 4
                    }
                }, {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 2
                    }
                }]
            })
        },
    },
    async mounted() {
        await this.getFlashSale();
        if(this.flashSaleAktif){
            this.loading= false
            this.label = this.flashSaleAktif.start > moment().unix() ? 'Dimulai':'Berakhir';
            this.countdown = this.flashSaleAktif.start > moment().unix() ? this.flashSaleAktif.start - moment().unix():this.flashSaleAktif.end - moment().unix();
            const stopCountdown = setInterval(() => {
                this.countdown -= 1
                if (!this.countdown) {
                    clearInterval(stopCountdown)
                    window.location.reload();
                }
            }, 1000)
            this.jqSlickFlashsale();
        }
    }
}
</script>

<style scoped lang="css">
    @import '../../assets/css/bootstrap.css';
    .flash-header img{
    width: 200px;
    }
    .countdownFlash p{
    font-size: large;
    }

    .countdownFlash i{
    color: #fff
    }
   .countdownFlash small{
    color: rgb(173, 173, 173);
    display: none;
    }

.countdownFlash span{
  display: block;
  font-weight: 800;
  font-size: x-large;
  color: #fff;
}

.countdownFlash li {
	display: inline-block;
  color: #fff;
  font-size: x-large;
  font-weight: 800;
}

    .countdownFlash span{
        /* display: flex; */
        font-weight: 800;
        font-size: x-large;
        color: #364B7B;
    }
    @media(min-width:600px){
  .separatorFlash{
    display: block;
  }
  .flash-header{
    display: flex;
  }
  .flashSaleImgHeader{
    margin: 0;
    margin-right: 10px;
  }

  .countdownFlash{
    /* display: flex; */
   padding: 5px 10px; text-align: center;
   background-color: #fff;
   justify-content: center;
  }

  .countdownFlash small{
    display: block;
  }
  .countdownFlash p{
    font-size: small;
  }

  .countdownFlash i{
    color: rgb(173, 173, 173);
  }
  
  
  
  .countdownFlash li {
    display: inline-block;
    color: #364B7B;
    font-size: x-large;
    font-weight: 800;
  }

  .countdownFlash{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px; text-align: center;
        gap: 5px;
    }

  


}


</style>