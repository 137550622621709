import apiurl from '@/util/apiurl'
const URL = apiurl.WS_TRANSACTION.WISHLIST;
export default {
    namespaced: true,
    state: {
        data: _.isEmpty(localStorage.getItem("wishlist")) ? null : JSON.parse(localStorage.getItem("wishlist")),
        data_guest: _.isEmpty(localStorage.getItem("wishlist_guest")) ? null : JSON.parse(localStorage.getItem("wishlist_guest")),
    },
    getters: {
        data: (state) => state.data,
        data_guest: (state) => state.data_guest,
    },
    mutations: {
        setData: (state, data) => {
            state.data = data;
            localStorage.setItem("wishlist", JSON.stringify(state.data));
        },
        setDataGuest: (state, data) => {
            state.data_guest = data;
            localStorage.setItem("wishlist_guest", JSON.stringify(state.data_guest));
        },
        removeData: (state, data) => {
            _.pull(state.data, data);
            localStorage.setItem("wishlist", JSON.stringify(state.data));
        },
        removeDataGuest: (state, data) => {
            _.pull(state.data_guest, data);
            localStorage.setItem("wishlist_guest", JSON.stringify(state.data_guest));
        },
    },
    actions: {
        async fetchData({ commit, state },page) {
            let result = true;
            await URL.GET().then(response => {
                result = response.data.data;
                // console.log(result.results);
                commit("setData", result.results);
            }).catch(error => {
                result = false;
                // console.log("setData", error.response.data);
            });
            return result;
        },
        async fetchCreate({ commit, state }, data) {
            let status = false;
            await URL.SAVE(data).then(response => {
                let data = response.data.data;
                data.icon = _.find(state.banks, { id: parseInt(data.uni_bank_id) }).icon;
                let numbers = state.numbers;
                numbers[_.findIndex(numbers,{"id":data.id})] = data;
                commit("setNumbers", numbers);
                status = true;
                // console.log("updateNumber", response.data.data);
            }).catch(error => {
                // console.log("updateNumber", error);
            });
            return status;
        },
        async pushDataGuest({commit, state}, data) {
            let _data = [];
            if(state.data_guest) {
                let exists = false;
                _.forEach(state.data_guest, function(val) {
                    if(val.id == data.id) exists = true; return;
                });
                if(exists) {
                    _data = _.differenceWith(state.data_guest, [data], _.isEqual);
                    if(_data.length <= 0) _data = null;
                }
                else {
                    _data.push(...state.data_guest, data);
                }
            }
            else {
                _data.push(data);
            }
            commit("setDataGuest", _data);
        },
        async syncWishlistGuest({commit, state}) {
            if(state.data_guest?.length > 0) {
                let productIds = _.map(state.data_guest, 'id')
                await URL.SYNC_GUEST(productIds).then(response => {
                    commit("setDataGuest", null);
                });
            }
        }
    }
};