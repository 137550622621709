<template>
    <div class="ol-home-type-product__list-item">
        <div class="ol-home-type-product__list-item-wrapper">
            <div class="ol-home-type-product__list-item-title">
                <h4>{{title}}</h4>
                <a href="javascript:void(0)" @click="filtering()">Selengkapnya</a>
            </div>
            <div class="ol-home-type-product__list-item-box">
                <div v-for="product in products" :key="product.id" class="ol-home-type-product__list-item-loop">
                    <figure class="ol-home-type-product__list-item-loop-figure">
                        <router-link :to="product.bundling == 1 ? '/bundle/'+product.slug : '/product/'+product.slug" v-for="(image,i) in product.r_uni_product_images.slice(0, 3)" :key="image.id" :class="'ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--'+(i+1)">
                            <img :src="image.image_url" :alt="image.alt_text" :title="product.name" :onerror="'this.src='+noImage()">
                        </router-link>
                        <router-link :to="product.bundling == 1 ? '/bundle/'+product.slug : '/product/'+product.slug" v-if="product.r_uni_product_images.length < 1" class="ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--1">
                            <img :src="noImage()">
                        </router-link>
                    </figure>
                    <div class="ol-home-type-product__list-item-loop-desc">
                        <h3 class="ol-home-type-product__list-item-loop-title">
                            <router-link :to="product.bundling == 1 ? '/bundle/'+product.slug : '/product/'+product.slug" :title="product.name">{{product.name}}</router-link>
                        </h3>
                            <div v-if="!product.price_normal || product.price_normal == 'Rp0'" class="ol-home-product__content-item-list--item-price">
                                <i v-if="product.discount_max && product.discount_max != '0%'" class="ol-home-product__content-item-list--item-price-discount-label">{{product.discount_max}} OFF</i>&nbsp;
                                <small class="ol-home-product__content-item-list--item-price-nodiscount">{{product.price_range}} 
                                </small>
                            </div>
                            <div v-else class="ol-home-type-product__list-item-loop-desc-price">
                                <span class="ol-home-product__content-item-list--item-price-real">{{product.price_normal}}</span>
                                <i v-if="product.discount_max && product.discount_max != '0%'" class="ol-home-product__content-item-list--item-price-discount-label">{{product.discount_max}} OFF</i>&nbsp;
                                <small class="ol-home-product__content-item-list--item-price-discount">{{product.price_range}}
                                </small>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ProducListBox",
    props:["products","title","productType"],
    methods:{
        filtering(){
            // console.log(this.productType)
			this.$store.commit("product/list/resetFilter",true);
            this.$store.commit("product/list/setCategoryGroup", null);
			this.$store.commit("product/list/setFiltered",{'type':this.productType});
			if(this.$route.name != "Products") this.$router.push('/products')
		},
    }
}
</script>

<style>

</style>