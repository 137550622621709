<template>
    <div class="ol-header-category-content">
        <div class="ol-wrapper ol-wrapper--large" id="ol-header-fix">
            <center>
            <ul class="ol-header-category__nav" style="width:max-content;">
                <li v-for="i in qty" :key="i" style="width: 200px;height: 35px;margin:8px;">
                    <SkeletonLoader/>
                </li>

            </ul>
            </center>
        </div>
    </div>
</template>
<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"CategoriesHeaderLoader",
    components:{
        SkeletonLoader
    },
    data(){
        return{
            qty:5,
        }
    },

}
</script>