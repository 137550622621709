export default {
    toRp : function(angka, rp = 1) {
        var rev = parseInt(angka, 10).toString().split('').reverse().join('');
        var rev2 = '';
        for (var i = 0; i < rev.length; i++) {
            rev2 += rev[i];
            if ((i + 1) % 3 === 0 && i !== (rev.length - 1)) {
                rev2 += '.';
            }
        }
        var Rp = (rp == 0) ? '' : 'Rp ';
        return Rp + rev2.split('').reverse().join('');
    },
    toNumberOnly: function (stringVal = '0') {
        if ((stringVal == '' || stringVal == null)) {
            return 0;
        } else {
            var a = stringVal.toString().replace(/[^0-9]/g, '');
            return parseFloat(a);
        }
    },
}