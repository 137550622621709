<template>
  <section class="ol-home-menu">
    <div class="ol-wrapper ol-wrapper--large">
      <div class="ol-home-menu-list-loader">
        <div class="ol-home-menu-list-slide" v-for="i in qty" :key="i">
          <a href="javascript:void(0)">
            <div
              class="ol-home-menu-list-item"
              style="width: 560px;height: 180px;"
            >
              <SkeletonLoader />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
export default {
  name: "BannerBottomLoading",
  components: {
    SkeletonLoader,
  },
  data() {
    return {
      qty: 2,
    };
  },
  methods: {
    jq() {
        $(".ol-home-menu-list-loader").not('.slick-initialized').slick({
          slidesToScroll: 1,
          slidesToShow: 2,
          dots: !1,
          autoplay: !0,
          autoplaySpeed: 5e3,
          arrows: !0,
          pauseOnFocus: !1,
          infinite: !0,
          speed: 500,
          fade: !1,
          responsive: [
            {
              breakpoint: 891,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
        
      }
  },
  mounted() {

    this.jq();
  },
};
</script>
