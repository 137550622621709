import citiesOverallData from "../../../public/json/cityOverall.json";
import apiurl from '@/util/apiurl'
const URL = apiurl.WS_PROFILE.ADDRESS;
const UNI = apiurl.WS_UNI;
export default {
    namespaced: true,
    state: {
        addresses: [],
        citiesOverallJson: citiesOverallData,
        provinces: _.isEmpty(localStorage.getItem("provinces")) ? null : JSON.parse(localStorage.getItem("provinces")),
    },
    getters: {
        addresses: (state) => state.addresses,
        citiesOverallJson: (state) => state.citiesOverallJson,
        provinces: (state) => state.provinces,
    },
    mutations: {
        setAddresses: (state, addresses) => (state.addresses = _.orderBy(addresses, ['default', 'created_at'], ['desc', 'asc'])),
        setCitiesOverallJson: (state, citiesOverallJson) => (state.citiesOverallJson = citiesOverallJson),
        setProvinces: (state, provinces) => (localStorage.setItem("provinces",JSON.stringify(provinces)))
    },
    actions: {
        async getCitiesOverallJson({commit,state}){
            let result = state.citiesOverallJson;
            if(state.citiesOverallJson.length < 1 ){
                await UNI.CITIES().then(response => {
                    result = response.data;
                    commit("setCitiesOverallJson", result.data);
                }).catch(error => {
                    // console.error("fetchCitiesOverallJson", error);
                    result = error.response.data;
                });
            }
            
            return result;
        },
        async fetchProvinces({ commit, state }) {
            let result = true;
            if (_.isEmpty(state.provinces)) {
                await UNI.PROVINCES().then(response => {
                    result = response.data;
                    commit("setProvinces", result.data);
                }).catch(error => {
                    result = false;
                    // console.log("fetchProvinces", error.response.data);
                });
            }
            return result;
        },
        async fetchAddresses({ commit, state }) {
            let result = true;
            if (_.isEmpty(state.addresses)) {
                await URL.GET().then(response => {
                    result = response.data;
                    commit("setAddresses", result.data);
                }).catch(error => {
                    result = false;
                    // console.log("fetchAddresses", error.response.data);
                });
            }
            return result;
        },
        async saveAddress({ commit, state }, input) {
            let result = null;
            await URL.STORE(input).then(response => {
                result = response.data;
                let addressList = state.addresses;
                if (result.data.default == 1) {
                    addressList = _.map(addressList, function (item) {
                        item.default = 0
                        return item;
                    });
                }
                addressList.push(result.data);
                commit("setAddresses", addressList);
            }).catch(error => {
                result = error.response.data;
                // console.log("saveAddress", result);
            });
            return result;
        },
        async updateAddress({ commit, state }, input) {
            let result = null;
            await URL.UPDATE(input).then(response => {
                result = response.data;
                let addressList = state.addresses;
                if (result.data.default == 1) {
                    addressList = _.map(addressList, function (item) {
                        item.default = 0
                        return item;
                    });
                }
                addressList[_.findIndex(addressList, { "id": result.data.id })] = result.data;
                commit("setAddresses", addressList);
            }).catch(error => {
                result = error.response.data;
                // console.log("updateAddress", result);
            });
            return result;
        },
        async delAddress({ commit, state }, id) {
            let result = null;
            await URL.DELETE({ id:id }).then(response => {
                result = response.data;
                commit("setAddresses", _.filter(state.addresses, (item) => item.id != result.data.id));
            }).catch(error => {
                result = error.response.data;
                // console.log("delAddress", result);
            });
            return result;
        },
    }
};