<template>
  <section class="ol-promotion-fs" v-if="flashsale_groups.length > 0">
    <div class="ol-wrapper ol-wrapper--large">
      <div class="ol-promotion-fs-title">
        <img :src="require('@/assets/images/fs.png')" class="image-fs"/>
        <span class="title-fs" >FLASH SALE!</span>
        <span class="title-sub-fs">{{status}} dalam </span>
        <span class="title-countdown-fs">{{countdown}}</span>
        
      </div>
      <div class="row no-gutters" id="row-flashsale-group">
        <div class="col-6" v-for="(flashsale, i) in flashsale_groups" :key="flashsale.title + flashsale.id">
          <a @click="changeActive($event, i)">
            <div :class="i === 0 ? 'ol-promotion-fs-box-active' : 'ol-promotion-fs-box'">
              <span class="title-group-fs">{{getHeadingText(flashsale, i)}}</span>
              <br>
              {{getOngoingText(flashsale)}}
            </div>
          </a>
        </div>
      </div>
      <div>
        <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="4" v-if="loading"/>
        <div class="ol-product-content__center-list carousel-products-container flashsale-group"
          v-for="(flashsale, i) in flashsale_groups"
          :key="flashsale.title + flashsale.id"
          :style="hiddenStyle(i !== selected)"
        >
        
          <div class="ol-home-product__content-item-list--item" style=""
            v-for="(product, j) in flashsale.r_more_promotion_flashsale_products"
            :key="product.r_uni_product_variants.name_long + product.r_uni_products.id + i + j"
          >
            <router-link :to="product.r_uni_product_variants.bundling == 1 ? '/bundle/'+product.r_uni_product_variants.slug : '/product/'+product.r_uni_product_variants.slug" :title="product.r_uni_product_variants.name_long">
              <div class="more-product-item">
                <div class="card-product-carausel">
                  <!-- <a v-if="product.r_uni_product_variants.stock == 0" >
                    <div v-if="product.r_uni_product_variants.stock == 0" class="label-stock-empty" >Stok Habis</div>
                    <div v-if="product.r_uni_product_variants.stock == 0" class="image-stock-empty" ></div>
                  </a> -->
                  <div v-if="product.r_uni_product_variant_images && product.r_uni_product_variant_images.length > 0"
                      :style="{
                          backgroundImage: 'url(' + product.r_uni_product_variant_images[0].image_url + ')',
                          display: 'block',
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover'
                      }">
                  </div>
                </div>
                <div class="content-card-product">
                    <h3 class="ol-home-product__content-item-list--item-title">
                      {{product.r_uni_product_variants.name_long}}
                    </h3>
                    <div class="flex-card-product">
                        <div class="price-discount-card-product">{{product.r_uni_products.price_normal}}</div>
                        <div class="box-discount-card-product">
                            <div class="discount-card-product">{{product.discount}}</div>
                        </div>
                    </div>
                    <div class="price-card-product">
                      {{getOngoingPrice(flashsale,product.price_rp)}}
                    </div>
                    <div class="review-card-product review-catalog-product" v-if="product.r_uni_products.total_sales != 0">
                        <span ><i class="fas fa-star" style="color:#FFCC00;"></i></span>
                        <span> {{product.r_uni_products.rating}}</span>
                        <span>|</span>
                        <span>{{product.r_uni_products.total_sales}}</span>
                        <span>Terjual</span>
                    </div>
                    <div class="review-card-product" v-else>
                        &nbsp;
                    </div>
                    <img  :src="require('@/assets/images/info-gratis-jawa-bali.png')" class="free-card-product">
                    <div v-if="product.r_uni_products" class="ol-home-flash-list__item-bar flashSaleStock">
                      <span>
                        <i class="currentStock" :style="'width:'+((product.remains/product.limit)*100)+'%'"></i>
                        <div class="stockText">
                          {{ product.remains == 0 ? 'Habis' : 'Stok Terbatas' }}
                        </div>
                      </span>
                    </div>
                    
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import  moment from '@/lib/moment';
import ProductLoader from '@/components/loading/product/Content';
import CarouselProduct from '@/components/home/CarouselProduct';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FlashsaleGroup',
  components: {
    ProductLoader,
    CarouselProduct,
  },
  data() {
    return {
      loading: false,
      selected: 0,
      seconds: 0,
      status: '',
      interval: null,
    }
  },
  computed: {
    ...mapGetters('promotion', ['flashsale_groups']),
    countdown() {
      if(this.flashsale_groups.length <= 0) return;

      const interval = moment.duration(this.seconds, 's');
      const d = Math.floor(interval.asDays()) >= 1 ? Math.floor(interval.asDays()) + ' hari ' : '';
      const hms = this.hms(interval.hours(), interval.minutes(), interval.seconds());
      // return d + hms;
      return hms;

      // return d + `${interval.hours()}:${interval.minutes()}:${interval.seconds()}`;
      // return moment.duration(this.seconds, 's').locale('id').humanize();
      // return moment.utc(moment.duration(this.seconds, "s").asMilliseconds()).format("D [hari] HH:mm:ss");
    },
  },
  methods: {
    ...mapActions('promotion', ['fetchFlashsaleGroup']),
    hms(h, m, s) {
      return ("0"+h).slice(-2) + ':' + ("0"+m).slice(-2) + ':' + ("0"+s).slice(-2);
    },
    setHeadingText(i = 0) {
      if(this.flashsale_groups.length <= 0) return;

      const now = moment().unix();
      const { start, end, date_start, date_end } = this.flashsale_groups[i];
      this.status = (now >= start) ? 'Berakhir' : 'Dimulai';
      this.seconds = (now >= start) ? end - now : start - now;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.seconds -= 1
        if (!this.seconds) {
          clearInterval(this.interval)
          window.location.reload();
        }
      }, 1000);
    },
    getHeadingText(flashsale, i) {
      const now = moment().unix();
      const { date_start, start } = flashsale;
      if(now >= start){
        if(i === 0) return moment().format('DD/MM/YYYY')
        return moment(flashsale.date_start, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY')
      }else{
        return moment(flashsale.date_start, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm');
      }
      
    },
    getOngoingText(flashsale) {
      const now = moment().unix();
      const { date_start, start } = flashsale;
      if(now >= start) return 'Berlangsung';
      return 'Akan datang';
      // return moment(date_start).locale('id').format("D MMMM YYYY")
    },
    getOngoingPrice(flashsale, price) {
      const now = moment().unix();
      const { date_start, start } = flashsale;
      if(now >= start) return price;
      // return price;
      return this.replaceNumbers(price);
      // return moment(date_start).locale('id').format("D MMMM YYYY")
    },
    replaceNumbers(input) {
      // Mengganti semua kemunculan titik (.)
          // let stringWithoutDot = input.replace(/\./g, '');

        // Mengganti semua kemunculan 'Rp'
        let finalString = input.replace(/Rp/g, '');
        return finalString.replace(/\d(?=\d{2,}\.)/g, '?');
    },
    changeActive(el, i) {
      const $row = document.querySelector('#row-flashsale-group');
      const $this = el.target;
      console.log($this);
      $row.childNodes.forEach(e => {
        e.firstChild.firstChild.className = 'ol-promotion-fs-box';
      });
      $this.className = 'ol-promotion-fs-box-active';
      this.selected = i;
      this.setHeadingText(i);
    },
    getProducts(flashsaleProducts) {
      let result = [];
      flashsaleProducts.forEach(e => {
        result.push(e.r_uni_products);
      });
      return result;
    },
    initSlick() {
      $(".carousel-products-container.flashsale-group").slick({
        slidesToScroll: 1,
        slidesToShow: 5,
        dots: !1,
        autoplay: !0,
        autoplaySpeed: 5e3,
        arrows: !0,
        pauseOnFocus: !1,
        infinite: !0,
        speed: 500,
        fade: !1,
        responsive: [{
          breakpoint: 550,
          settings: {
            slidesToShow: 2,
          },
        }],
      });
    },
    hiddenStyle(hidden = true) {
      if(hidden) return 'height: 0px; overflow-y: hidden;';
      return '';
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchFlashsaleGroup();
    if(this.flashsale_groups.length > 0) {
      this.setHeadingText(0);
      this.initSlick();
    }
    this.loading = false;
  }
}
</script>