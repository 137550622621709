import cart from "@/store/transaction/cart";
import checkout from "@/store/transaction/checkout";

export default {
    namespaced:true,
    modules: {
        cart,
        checkout,
    },
};
