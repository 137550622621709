<template>
    <div class="ol-alert ol-alert--success">
        <i class="ol-fa fas "></i> {{pesan}}
    </div>
</template>

<script>
export default {
 props: ['pesan'],
 name:"alert-success"
}
</script>