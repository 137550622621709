<template>
    <div id="BaseLayout">
        <Header/>
        <section class="ol-body" style="width: 100%!important;">
            <slot></slot>
        </section>
        <Footer/>
    </div>
  
</template>

<script>
import Footer from '@/components/landing/Footer.vue'
import Header from '@/components/landing/Header.vue'
import { mapGetters, mapActions } from "vuex";

export default {
    name:"BaseLayout",
    components:{
        Header,
        Footer,
    },
    data(){
        return {
            currentMetax: null,
        }
    },
    computed: {
		...mapGetters("home", ["metaPages"]),
        currentMeta(){
            return _.find(this.metaPages, {"slug":this.$route.name});
        }
	},
	methods: {
		...mapActions("home", ["fetchMetaPages"]),
		...mapActions("product/detail", ["addUtmContent"]),
    },
    mounted(){
        this.fetchMetaPages();
        if(this.$route.query?.utm_content) {
            this.addUtmContent(this.$route.query.utm_content);
        }
    },
    updated(){
        this.fetchMetaPages();
    },
    metaInfo() {
        if(this.currentMeta){
            var tags = this.$route.query.tags ? this.$route.query.tags : null;
            if(tags != null){
                var meta_title = tags+" | MORE Furniture";
            }else{
                var meta_title = this.currentMeta ? this.currentMeta.meta_title : "";
            }
            return {
                title: meta_title,
                meta: [
                    { name: 'description', content: this.currentMeta ? this.currentMeta.meta_desc : ""},
                    { name: 'keywords', content: this.currentMeta ? this.currentMeta.keyword : ""},
                    { name: 'robots', content: 'index,follow'},
                    { property: 'og:title', content: this.currentMeta.meta_title },
                    { property: 'og:description', content: this.currentMeta.meta_desc ? this.currentMeta.meta_desc : ''},
                    { property: 'og:site_name', content: 'More Furniture'},
                    { property: 'og:type', content: 'website'}  
                ]
            }
        }else{
            return {
                meta:[],
            }
        }
    },
}
</script>

<style>

</style>