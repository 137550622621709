<template>
  <div  class="ol-wrapper ol-wrapper--large">
    <div class="title-div-content">
      <h2 class="heading-content">Rekomendasi Selebgram</h2>
      <span class="sub-heading-content">Yuk Lihat Rekomendasi Furnitur oleh Seleb Indonesia!</span>
    </div>
    <div v-if="loading" class="container-endorsement ol-home-endorsement-list">
      <SkeletonLoader />
    </div>
    <div v-else class="container-endorsement ol-home-endorsement-list">
      <div class="card-endorsement"  v-for="(item, i) in endorsementJson" :key="i">
        <div class="card-endorsement-header">
          <a :href="item.link" >
            <img :src="item.image_content" :alt="item.ig" />
          </a>
        </div>
        <div class="card-endorsement-body">
          <div class="user">
            <img :src="item.image_user" :alt="item.name" />
            <div class="user-info">
              <a :href="item.link" >
                <div style="font-weight: 700;font-size: 12px;line-height: 18px;">{{item.name}}</div>
                <small>@{{item.ig}}</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import {mapActions,mapGetters} from "vuex";

export default {
  name: "Endorsement",
  components:{
    SkeletonLoader
  },
  data() {
    return {
      loading:true
    };
  },
  computed:{
    ...mapGetters("home",["endorsementJson"]),
  },
  methods:{
    ...mapActions('home',['getEndorsementJson']),
  },
  async mounted() {
    this.loading = true;
    await this.getEndorsementJson();
    this.loading = false;
  },
  updated() {
    $(".container-endorsement").slick({
      slidesToScroll: 1,
      slidesToShow: 5,
      dots: !1,
      autoplay: !0,
      autoplaySpeed: 5e3,
      arrows: !0,
      pauseOnFocus: !1,
      infinite: !0,
      speed: 500,
      fade: !1,
      responsive: [{
        breakpoint: 891,
        settings: {
          slidesToShow: 2,
        },
      }, {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
        },
      }],
    });
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/css/_endorsement.css";
@import "../../assets/js/slick/slick.css";
</style>
