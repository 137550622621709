<template>
<BlockUI :message="msg" :html="html"></BlockUI>  
</template>
<script>
export default {
    name:"LoadingImg",
    data(){
        return{
            msg: 'Mohon menunggu..',
      	    html: '<img src="'+require('../../assets/loading.gif')+'" width="100px" />' 
        }
    }
}
</script>