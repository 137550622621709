<template>
  <section class="" style="height:480px;width:100%;">
        <SkeletonLoader />
  </section>
</template>
<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
export default {
  name: "CarouselLoader",
  components: {
    SkeletonLoader,
  },
  data() {
    return {
      qty: 12,
    };
  },
};
</script>
