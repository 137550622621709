import endorsementData from "../../../public/json/endorsement.json";
import apiurl from '@/util/apiurl';
import * as moment from 'moment'
const HOME = apiurl.WS_HOME;
const PRODUCT = apiurl.WS_PRODUCT;

export default {
    namespaced:true,
    state: {
        bannerTop: [],
        bannerBottom: [],
        popup: [],
        produtTerbaik: [],
        setting: [],
        inspirasi: [],
        endorsement: [],
        endorsementJson: endorsementData,
        flashSale: [],
        productGroups: [],
        metaPages: [],
        waText: "Hai+MORE%2C+saya+ingin+bertanya",
    },
    getters: {
        bannerTop: (state) =>state.bannerTop,
        bannerBottom: (state) => state.bannerBottom,
        produtTerbaik: (state) => state.produtTerbaik,
        popup:(state) => state.popup,
        setting: (state) => state.setting,
        allSetting: (state) => {
            let data = {};
            let tag = _.find(state.setting, {id:1});
            let tag_d = _.find(state.setting, {id:2});
            let wa =  _.find(state.setting, {id:8});
            let email = _.find(state.setting, {id:7});
            let yt = _.find(state.setting, {id:6});
            let fb = _.find(state.setting, {id:3});
            let ig = _.find(state.setting, {id:4});
            let tk = _.find(state.setting, { id: 5 });

            if(tag) data['tagline_title'] = tag.content;
            if(tag_d) data['tagline_desc'] = tag_d.content;
            if(wa) data['wa_no'] =wa.content;
            if(wa) data['wa_link'] = wa.description;
            if(email) data['email'] =email.content;
            if(email) data['email_to']= 'mailto:'+email.content;
            if(yt) data['yt'] = yt.content;
            if(fb) data['fb'] = fb.content;
            if(ig) data['ig'] = ig.content;
            if(tk) data['tk'] = tk.content;
            
            return data;
        },
        inspirasi:(state) => state.inspirasi,
        endorsement:(state) => state.endorsement,
        endorsementJson:(state) => state.endorsementJson,
        flashSaleAktif:(state) =>{
            let data = null;
            // console.log(state.flashSale)
            state.flashSale.map((item)=>{
            
                let timestamp = moment().unix();
                let startDate = item.start;
                let endDate = item.end;
                if(timestamp < endDate ){
                    if(!data)
                    data = item
                }
            });
            // console.log(data);
            return data;
        },
        productGroups: (state) => state.productGroups,
        metaPages: (state) => state.metaPages,
        waText: (state, getters, rootState, rootGetters) => {
            let user = rootGetters['auth/user'];
            let productDetail = rootGetters['product/detail/product'];
            
            if(productDetail) {
                state.waText = 'Nama+Produk:+'+productDetail.name+'%0aLink:+https://link.morefurniture.id/product/'+productDetail.slug;
                state.waText += "+%0aHai+MORE%2C+saya+ingin+bertanya+%0a";
            }else{
                state.waText = "Hai+MORE%2C+saya+ingin+bertanya+%0a";
            }
            
            if(user) {
                state.waText += '+%0aNama+Pengguna:+'+user.name+'%0aEmail:+'+user.email;
            }
            state.waText += '+%0aTujuan Pengiriman:+';
            return state.waText;
        },
        // currentMeta:()=> _.find(state.metaPages, {"slug": this.$route.name}),

    },
    mutations: {
        setBannerTop: (state, bannerTop) => (state.bannerTop = bannerTop),
        setBannerBottom: (state, bannerBottom) => (state.bannerBottom = bannerBottom),
        setPopup: (state, popup) => (state.popup = popup),
        setProdutTerbaik: (state, produtTerbaik) => (state.produtTerbaik = produtTerbaik),
        setSetting:(state, setting) => (state.setting = setting),
        setInspirasi:(state, inspirasi) => (state.inspirasi = inspirasi),
        setEndorsement: (state, endorsement) => (state.endorsement = endorsement),
        setEndorsementJson: (state, endorsementJson) => (state.endorsementJson = endorsementJson),
        setFlashSale:(state, flashSale) => (state.flashSale = flashSale),
        setProductGroups:(state, productGroups) => (state.productGroups = productGroups),
        setMetaPages:(state, payload) => (state.metaPages = payload),
    },
    actions: {
        
        async getBanner({commit,state},type){
            let data={
                application : 'MORE',
                type:type
            };
            let result = { success: false };
            if(_.isEmpty(state.bannerTop) || _.isEmpty(state.bannerBottom) ){
                // console.log($.param(data))
                await HOME.GET_BANNER(data).then(response => {
                    result = response.data;
                    // console.log(result.data.results);
                    let vardata= result.data.results;
                    let _data =[];
                    vardata.forEach(element => {
                        // console.log(element);
                        _data.push({
                            web:element.image_desktop_url,
                            mobile:element.image_mobile_url,
                            link_banner:element.link_banner,
                        });
                    }); 
                    // console.log(_data);
                    if(type == '1'){
                        commit("setBannerTop", _data);
                    }else{
                        commit("setBannerBottom", _data);
                    }
                }).catch(error => {
                    // console.log("setBanner", error);
                    result = error.response.data;
                    // console.log("setBanner", error.response.data);
                });
                return result;
            }
            type == '1' ? result = state.bannerTop:state.bannerBottom;
            return result;

        },
        async getPopup({commit,state}){
            let result = state.popup;
            if(state.popup.length < 1 ){
                await HOME.GET_POP_UP().then(response => {
                    result = response.data;
                    // console.log("setPopup", result);
                    commit("setPopup", result.data.results);
                }).catch(error => {
                    // console.log("setPopup", error);
                    result = error.response.data;
                    // console.log("setBanner", error.response.data);
                });
            }
            return result;
        },
        async getProdutTerbaik({commit,state}){
            let result = { success: false };
            if(state.produtTerbaik.length < 1 ){
                await HOME.GET_PRODUCT_GROUP().then(response => {
                    result = response.data;
                    commit("setProdutTerbaik", result.data.results);
                }).catch(error => {
                    result = error.response.data;
                    // console.log("setProdutTerbaik", error.response.data);
                });
                return result;
            }
        },
        async getSetting({commit,state}){
            let result = state.setting;
            if(state.setting.length < 1 ){
                await HOME.GET_SETTING().then(response => {
                    result = response.data.data;
                    // console.log(result.data);
                    commit("setSetting", result);
                }).catch(error => {
                    result = error.response.data;
                    // console.log("setSetting", error.response.data);
                });
                
            }
            return result;
        },
        async getInspirasi({commit,state}){
            let result = state.inspirasi;
            if(state.inspirasi.length < 1 ){
                await HOME.GET_INSPIRASI().then(response => {
                    result = response.data.data;
                    // console.log(result);
                    commit("setInspirasi", result);
                }).catch(error => {
                    result = error.response.data;
                    // console.log("setInspirasi", error.response.data);
                });
                
            }
            return result;
        },

        async getEndorsement({commit,state}){
            let result = state.endorsement;
            if(state.endorsement.length < 1 ){
                await HOME.GET_ENDORSEMENT().then(response => {
                    result = response.data.data;
                    // console.log(result);
                    commit("setEndorsement", result);
                }).catch(error => {
                    result = error.response.data;
                    // console.log("setEndorsement", error.response.data);
                });
                
            }
            return result;
        },

        async getEndorsementJson({commit,state}){
            let result = state.endorsementJson;
            if(state.endorsementJson.length < 1 ){
                await HOME.GET_ENDORSEMENT().then(response => {
                    result = response.data.data;
                    // console.log(result);
                    commit("setEndorsementJson", result);
                }).catch(error => {
                    result = error.response.data;
                    // console.log("setEndorsement", error.response.data);
                });
                
            }
            return result;
        },

        async getFlashSale({commit,state}){
            let result = state.flashSale;
            await HOME.GET_FLASHSALE().then(response => {
                result = response.data.data;
                commit("setFlashSale", result);
            }).catch(error => {
                result = error.response.data;
                // console.log("getFlashSale", error.response.data);
            });
            return result;
        },

        async fetchProductGroups({state, commit }) {
            let result = { success: false };
            if (_.isEmpty(state.productGroups)) {
                await PRODUCT.GROUPS().then(response => {
                    result = response.data;
                    commit("setProductGroups", result.data);
                }).catch(error => {
                    // console.error("fetchProductGroups", error);
                    result = error.response.data;
                });
            }
            return result;
        },

        async fetchMetaPages({state, commit }) {
            let result = { success: false };
            if (_.isEmpty(state.metaPages)) {
                await HOME.GET_METAS().then(response => {
                    result = response.data;
                    commit("setMetaPages", result.data);
                }).catch(error => {
                    // console.error("fetchMetaPages", error);
                    result = error.response.data;
                });
            }
            return result;
        },

    }
};