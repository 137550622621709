<template>
    <div>
        <section v-if="loading" class="ol-home-category">
            <div v-for="i in 1" :key="i+'-loader'" class="ol-wrapper ol-wrapper--large">
                <h2 class="heading" style="width:100%;height:50px;">
                    <SkeletonLoader />
                </h2>
                <div class="row no-gutters">
                    <div class="col-12 ">
                        <div class="grid-category">
                            <div class="grid-item" v-for="j in 2" :key="j+'-loader'" style="width:200px;height:200px;margin:5px;"> <SkeletonLoader /> </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </section>
        <section v-else class="ol-home-flash" v-for="group in groups" :key="'group-content-'+group.id">
                <div class="ol-wrapper ol-wrapper--large">
                    <div class="title-div-content">
                        <h2 class="heading-content">{{group.name}}</h2>
                        <span class="sub-heading-content">{{group.description}}</span>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-6 col-lg-2 ">
                            <div class="ol-home-flash-list__item prodterbaruBanner" style="background-color: #364B7B ;">
                                <div class="figureFlashContainer">
                                    <figure style="text-align:center">
                                        <img  :src="group.image_url" style="width:100%;">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-10 ">
                            <CarouselProductGroup :products="group.r_more_product_group_products"  carouselKey="groupProduct" />
                        </div>
                    </div>
                </div>
        </section>
        <div class="break-24"></div>
    </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import CarouselProductGroup from '@/components/home/CarouselProductGroup';
export default {
    name:"ProducListGroup",
    props:["groups"],
    components:{
        SkeletonLoader,
        CarouselProductGroup
    },
    data(){
        return {
            loading: true,
        }
    },
    methods:{
        // initSlider(){
        //     console.log("initSlider");
        //     $(".ol-home-flash-list").slick({
        //             slidesToScroll: 1,
        //             slidesToShow: 5,
        //             dots: !1,
        //             autoplay: !0,
        //             autoplaySpeed: 5e3,
        //             arrows: !0,
        //             pauseOnFocus: !1,
        //             infinite: !0,
        //             speed: 500,
        //             fade: !1,
        //             responsive: [{
        //                 breakpoint: 891,
        //                 settings: {
        //                     slidesToShow: 4
        //                 }
        //             }, {
        //                 breakpoint: 481,
        //                 settings: {
        //                     slidesToShow: 1
        //                 }
        //             }]
        //         })
        // }
    },
    mounted(){
        if(this.groups.length > 0) this.loading = false;
    },
    watch:{
        "groups":function(value){
            if(value.length > 0) this.loading = false;
        }
    }
}
</script>

<style lang="css" scoped>
@import '../../assets/css/bootstrap.css';
</style>
