<template>
  <section class="ol-home-menu">
    <div class="ol-wrapper ol-wrapper--large">
      <div class="ol-home-menu-list" :v-if="banners.length > 0">
        <div class="ol-home-menu-list-slide" v-for="(item, index) in banners" :key="index" >
          <a :href="item.link_banner ? item.link_banner : '#'">
            <div class="ol-home-menu-list-item">
              <img :srcset="item.web" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BannerBottom",
  props: ["banners"],
  methods: {
    jq() {
        // $('.ol-home-menu-list').slick('unslick');
        $(".ol-home-menu-list").slick({
          slidesToScroll: 1,
          slidesToShow: 3,
          dots: !1,
          autoplay: !0,
          autoplaySpeed: 5e3,
          arrows: !0,
          pauseOnFocus: !1,
          infinite: !0,
          speed: 500,
          fade: !1,
          responsive: [
            {
              breakpoint: 891,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
    },
  },
  updated: function() {
    this.$nextTick(function() {
        this.jq();
    });
  },
  
};
</script>

<style></style>
