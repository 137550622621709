import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyD9FfhX-T95OS89WB8uJUSWPztrMwcl7Pw",
    authDomain: "more-306503.firebaseapp.com",
    projectId: "more-306503",
    storageBucket: "more-306503.appspot.com",
    messagingSenderId: "24556042365",
    appId: "1:24556042365:web:887c3b13ec35009d529914",
    measurementId: "G-MRZ9DMPX3W"
};
const app = initializeApp(firebaseConfig);


