import address from "@/store/profile/address";
import dropshipper from "@/store/profile/dropshipper";
import bank from "@/store/profile/bank";
import event from "@/store/profile/event";
import order from "@/store/profile/order";
import product from "@/store/profile/product";
import wishlist from "@/store/profile/wishlist";

export default {
    namespaced:true,
    modules: {
        bank,
        address,
        dropshipper,
        order,
        product,
        event,
        wishlist,
    },
};
