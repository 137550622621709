<template>
    <div class="ol-alert ol-alert--danger">
        <i class="ol-fa fas fa-times"></i> {{pesan}}
    </div>
</template>

<script>
export default {
 props: ['pesan'],
 name:"alert-error",
 data(){
     return {
       
     }
 },
 methods:{
     del(){

     }
 }
}
</script>