<template>
    <div :class="'skeleton '+classes" :style="'width:'+_width+'!important;height:'+_height+'!important;border-radius:'+_corner+';background:'+_background+';'"></div>
    <!-- <div :class="'skeleton '+classes" :style="'width:'+_width+';height:'+_height+';border-radius:'+_corner+';background:'+_background+';margin:'+_margin+';'"></div> -->
</template>

<script>
export default {
    name: 'skeletonLoader',
    props: {
        width: { type: [String, Number], default: '100%' },
        height: { type: [String, Number], default: '100%' },
        margin: { type: [String, Number], default: 5 },
        corner: { type: [String, Number], default: 1 },
        theme: { type: String, default: 'light' },
        classes: { type: String, default: '' },
    },
    methods:{
        isNumeric(value) {
            return /^\d+$/.test(value);
        },
    },
    computed:{
        _margin(){
            return this.isNumeric(this.margin) ? this.margin + 'px' : this.margin;
        },
        _width(){
            return this.isNumeric(this.width) ? this.width + 'px' : this.width;
        },
        _height(){
            return this.isNumeric(this.height) ? this.height + 'px' : this.height
        },
        _corner(){
            return this.isNumeric(this.corner) ? this.corner + 'px' : this.corner
        },
        _background(){
            return this.theme === 'dark' ? 'rgba(255,255,255, 0.2)' : '#F5F5F5'
        },
        
    },
}
</script>
<style lang="css" scoped>
    .skeleton {
        position: relative;
        overflow: hidden;
    }
    .skeleton::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(177, 177, 177, 0.5),
            transparent
        );
        animation: wave 1.3s linear 0.5s infinite;
    }
    @keyframes wave {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        65% {
            opacity: 1;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(100%);
        }
    }
</style>