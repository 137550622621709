import Vue from 'vue'

// axios
import axios from 'axios'

import store from '../store'
import router from '../router/index'

const baseUrl=process.env.VUE_APP_BASE_API_URL;

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseUrl+"/",
  // timeout: 1000,

  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Authorization, X-Auth-Token",
    "Access-Control-Max-Age": "3600",
  }
})

axiosIns.interceptors.request.use(config => {
  if (config.method === 'POST' || config.method === 'PATCH' || config.method === 'PUT')
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

  
  if ( store.state.auth.accessToken){
    const accessToken = localStorage.getItem("accessToken");
    // console.log("axios",accessToken);
    config.headers.Authorization = 'Bearer ' + accessToken;
  }

  return config;
});

axiosIns.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  // console.log("interceptor",error.response);
    const status = error.response.status;
    if (store.getters['auth/authenticated'] && status === 401) {
      await store.dispatch("auth/logout", false);
      router.replace("/?unAuth");
      // this.this._vm.$toast.open({ message: "Sesi telah habis, silahkan login kembali", type: "warning", duration: 5000, });
    } else if(status === 404){
      router.push("/404");
    }
  return Promise.reject(error);
});


Vue.prototype.$http = axiosIns

export default axiosIns