import apiurl from '@/util/apiurl'
import { _ } from 'core-js';
const URL = apiurl.WS_PROFILE.EVENT;

export default {
    namespaced: true,
    state: {
        myClaims: [],
        myClaimsInfo: {
            total: 0,
            total_pages: 0,
            page_active:1,
        },
        events:[],
        tracking:[],
    },
    getters: {
        myClaims: (state) => state.myClaims,
        myClaimsInfo: (state) => state.myClaimsInfo,
        events: (state) => state.events,
        tracking: (state) => state.tracking,
    },
    mutations: {
        setMyClaims: (state, payload) => (state.myClaims = payload),
        setMyClaimsInfo: (state, payload) => (state.myClaimsInfo = payload),
        setEvents: (state, payload) => (state.events = payload),
        setTracking: (state, payload) => (state.tracking = payload),
    },
    actions: {
        async fetchMyClaims({ commit, state }, payload) {
            let result = { success: true };
            await URL.MY_CLAIMS(payload).then(response => {
                result = response.data;
                commit("setMyClaims", result.data.results);
                commit("setMyClaimsInfo", {
                    total:result.data.total,
                    total_pages: result.data.total_pages,
                    page_active: payload.page,
                });
            }).catch(error => {
                // console.log("fetchMyClaims", error);
                result = error.response;
            });
            return result;
        },
        async fetchEvents({ commit, state }) {

            let result = { success: true };
            if (_.isEmpty(state.events)) {
                await URL.EVENT_LIST().then(response => {
                    result = response.data;
                    commit("setEvents", result.data);
                }).catch(error => {
                    // console.log("fetchEvents", error);
                    result = error.response.data;
                });
            }
            // console.log(result);
            return result;
        },
        async fetchTracking({ commit, state },payload) {
            let result = { success: true };
            await URL.TRACKING(payload).then(response => {
                result = response.data;
                commit("setTracking", result.data);
            }).catch(error => {
                // console.log("fetchTracking", error);
                result = error.response.data;
            });
            return result;
        },
        async saveClaim({ commit, state }, payload) {
            let result = { success: true };
            await URL.STORE(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.log("save", error);
                result = error.response.data;
            });
            return result;
        },
        async deleteClaim({ commit, state }, id) {
            let result = { success: true };
            await URL.DELETE(id).then(response => {
                result = response.data;
            }).catch(error => {
                // console.log("delete", error);
                result = error.response.data;
            });
            return result;
        },
    }
}