import Vue from 'vue'
import Vuex from "vuex"
import _ from 'lodash';
import auth from "@/store/auth/auth";
import product from "@/store/product";
import profile from "@/store/profile";
import home from "@/store/home/home";
import promotion from "@/store/home/promotion";
import transaction from "@/store/transaction";
import notification from "@/store/notification/notification";
import artikel from "@/store/artikel/artikel";
import chats from "@/store/chats/chats";
import moreverse from "@/store/moreverse";
import interior from "@/store/interior";


Vue.use(Vuex);
Vue.use(_);

const store = new Vuex.Store({
    modules: {
        auth,
        profile,
        product,
        home,
        promotion,
        transaction,
        notification,
        artikel,
        chats,
        moreverse,
        interior
    }
});

export default store;