import apiurl from '@/util/apiurl'
import helpme from '@/util/helpme'

const URL = apiurl.WS_AUTH;
const OTP = apiurl.WS_OTP;

export default {
    namespaced:true,
    state: {
        authenticated: !JSON.parse(localStorage.getItem("user")) ? false : true,
        accessToken: _.isEmpty(localStorage.getItem("accessToken")) ? null : localStorage.getItem("accessToken"),
        user: !JSON.parse(localStorage.getItem("user")) ? null : JSON.parse(localStorage.getItem("user")),
        moreversary: !JSON.parse(localStorage.getItem("moreversary")) ? null : JSON.parse(localStorage.getItem("moreversary")),
        progressProfile:null,
        location: !JSON.parse(localStorage.getItem("location")) ? null : JSON.parse(localStorage.getItem("location")),
        fbToken:! _.isEmpty(localStorage.getItem("fbtoken")) ? null : localStorage.getItem("fbtoken")
    },
    getters: {
        user: (state) => state.user,
        moreversary: (state) => state.moreversary,
        accessToken: (state) => state.accessToken,
        fbToken:(state) => state.fbToken,
        authenticated: (state) => {
            let auth = state.authenticated;
            if (state.accessToken && state.user) auth = true;
            return auth;
        },
        progressProfile: (state) => state.progressProfile,
        location: (state) => state.location,
    },
    mutations: {
        setAuthentication: (state, authenticated) => {
            if (state.accessToken && state.user) authenticated = true;
            state.authenticated = authenticated;
        },
        setAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
            accessToken ? localStorage.setItem("accessToken", accessToken) : localStorage.removeItem("accessToken");
        },
        setFbToken: (state, fbToken) => {
            state.fbToken = fbToken;
            fbToken ? localStorage.setItem("fbtoken", fbToken) : localStorage.removeItem("fbtoken");
        },
        setUser: (state, user) => {
            if (user && !_.has(user, 'have_password')) {
                user.have_password = state.user && _.has(state.user, 'have_password') ? state.user.have_password : true;
            }
            state.user = user;
            user ? localStorage.setItem("user", JSON.stringify(user)) : localStorage.removeItem("user");
        },
        setProgressProfile: (state, progressProfile) => (state.progressProfile = progressProfile),
        setMoreversary: (state, moreversary) => (state.moreversary = moreversary),
        setLocation: (state, location) => {
            state.location = location;
            localStorage.setItem("location", JSON.stringify(location));
        },
    },
    actions: {
        async logout({ commit, dispatch }, hitApi = true) {
            if(hitApi) await URL.LOGOUT();
            await commit("setUser", null);
            await commit("setAuthentication", false);
            await commit("setLocation", null);
            await commit("profile/wishlist/setData", null, {root: true});
            await commit("product/detail/setProduct", null, {root: true});
            await commit("transaction/cart/setCart2Empty", null, {root: true});
            await dispatch("notification/logout", null, {root: true});
            await commit("setAccessToken", null);
        },
        async loginUser({ commit, dispatch }, input) {
            let result = null;
            await URL.LOGIN(input).then(response => {
                result = response.data;
                commit("setAccessToken", result.data.token);
            }).catch(error => {
                dispatch("logout");
                // console.log(error);
                result = error.response.data;
                // console.log(result);
                // console.log("loginUser", result);
            });
            return result;
        },
        async registerUser({ commit, dispatch }, input) {
            let result = null;
            await URL.REGISTER(input).then(response => {
                result = response.data;
                commit("setAccessToken", result.data.token);
            }).catch(error => {
                dispatch("logout");
                result = error.response.data;
                // console.log("loginUser", result);
            });
            return result;
        },
        async loginUserGoogle({ commit, dispatch }, input) {
            let result = null;
            await URL.LOGIN_GOOGLE(input).then(response => {
                result = response.data;
                // console.log(result);
                commit("setAccessToken", result.data.token);
            }).catch(error => {
                dispatch("logout");
                result = error.response.data;
                // console.log("loginUserGoogle", result);
            });
            return result;
        },
        async loginUserFb({ commit, dispatch }, input) {
            let result = null;
            await URL.LOGIN_FB(input).then(response => {
                result = response.data;
                // console.log(result);
                commit("setAccessToken", result.data.token);
            }).catch(error => {
                dispatch("logout");
                result = error.response.data;
                // console.log("loginUserFb", result);
            });
            return result;
        },
        async fetchUser({ commit,getters, dispatch,state }) {
            let result = null;
            if (getters.accessToken) {
                await URL.SHOW().then(response => {
                    result = response.data;
                    commit("setUser", result.data);
                    commit("setAuthentication");
                    dispatch("havePassword");
                    dispatch("fetchProgressProfile");
                }).catch(error => {
                    // console.log(error.response?error.response:error)
                    result = error.response.data;
                    // console.log("fetchUser",result);
                });

            }
            return result;
        },
        async saveUser({ commit,dispatch }, input) {
            let status = false;
            await URL.UPDATE_USER(input).then(response => {
                let data = response.data.data;
                commit("setUser", data);
                dispatch("fetchProgressProfile");
                status = true;
            }).catch(error => {
                // console.log("saveUser", error.response.data);
            });
            return status;
        },
        async savePhoto({ commit, state }, file) {
            let status = false;
            let fd = new FormData();
            fd.append('picture', file);

            await URL.SAVE_PHOTO(fd).then(response => {
                let data = response.data.data;
                let user = state.user;
                user.picture = data.picture;
                commit("setUser", user);
                status = true;
            }).catch(error => {
                // console.log("savePhoto", error.response.data);
            });
            return status;
        },
        async saveMail({ commit,dispatch }, input) {
            let result = null;
            await URL.UPDATE_MAIL(input).then(response => {
                result = response.data;
                commit("setUser", result.data);
                dispatch("fetchProgressProfile");
                status = true;
            }).catch(error => {
                result = error.response.data;
                // console.log("saveMail", result);
            });
            return result;
        },
        async sendMail_OTP({},input) {
            // console.log(input);
            let result = null;
            await OTP.SEND_MAIL_OTP(input).then(response => {
                result = response.data;
            }).catch(error => {
                result = error.response.data;
                // console.log("sendMail_OTP", result);
            });
            return result;
        },
        async validMail_OTP({},otp) {
            let result = null;
            await OTP.VALID_MAIL_OTP(otp).then(response => {
                result = response.data;
            }).catch(error => {
                result = error.response.data;
                // console.log("validMail_OTP", result);
            });
            return result;
        },
        async sendWA_OTP({},input) {
            // console.log(input);
            let result = null;
            await OTP.SEND_WA_OTP(input).then(response => {
                result = response.data;
            }).catch(error => {
                result = error.response.data;
                // console.log("sendWA_OTP", result);
            });
            return result;
        },
        async validWA_OTP({},data) {
            let result = null;
            await OTP.VALID_WA_OTP(data.otp, {phone:data.phone}).then(response => {
                result = response.data;
            }).catch(error => {
                result = error.response.data;
                // console.log("validWA_OTP", result);
            });
            return result;
        },
        async havePassword({commit,state}) {
            let result = null;
            let user = state.user;
            user.have_password = true;
            await URL.HAVE_PWD().then(response => {
                result = response.data;
                user.have_password = result.data.status;
            }).catch(error => {
                result = error.response.data;
                // console.log("havePassword", result);
            });
            commit("setUser", user);
        },
        async fetchProgressProfile({ commit,state }) {
            let result = null;
            await URL.PROGRESS_PROFILE().then(response => {
                result = response.data;
                commit("setProgressProfile", result.data);
            }).catch(error => {
                result = error.response.data;
                // console.log("progressProfile", result);
            });
            return result;
        },
        async fetchMoreversary({ commit,state }) {
            let result = null;
            await URL.MOREVERSARY().then(response => {
                result = response.data;
                commit("setMoreversary", result.data);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async SaveUserToken({commit,state},data) {
            let result = null;
            await URL.SAVE_TOKEN(data).then(response => {
                result = response.data;
                commit("setFbToken", result.data.firebase_token);
            }).catch(error => {
                // console.log("SaveUserToken", error);
                result = error.response.data;
                // console.log("SaveUserToken", result);
            });
            return result;
        },
        async saveLocation({},data) {
            let result = null;
            await URL.SAVE_LOCATION(data).then(response => {
                result = response.data;
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
    }
};
