<template>

    <section class="ol-home-category">
        
        <div class="ol-wrapper ol-wrapper--large">
            <div class="title-div-content">
                <h2 class="heading-content">Kategori Produk</h2>
                <span class="sub-heading-content">Jelajahi Furnitur Ramah di Kantong Favorit Keluarga Indonesia!</span>
            </div>
            <div v-if="loading" class="grid-category">
                <div class="grid-item" v-for="i in loadingItem" :key="i+'-loader'" style="width:200px;height:200px;margin:5px;"> <SkeletonLoader /> </div>
            </div>
            <div v-else class="grid-category">
                <div class="grid-item" v-for="category in categories" :key="category.id">
                    <!-- <router-link v-if="category.name != 'Springbed' " :to="'/products/category_group='+category.id" @click.native="scrollToTop()"> -->
                    <router-link v-if="category.name != 'Springbed' " :to="'/category/'+category.name.toLowerCase().replaceAll(' ','-')" @click.native="scrollToTop()">
                        <img :srcset="category.image_url"  :alt="category.name" :title="category.name">
                    </router-link>
                    <router-link v-if="category.name == 'Springbed' " :to="'/category/springbed-kasurbusa'" @click.native="scrollToTop()">
                        <img :srcset="category.image_url"  :alt="category.name" :title="category.name">
                    </router-link>
                    <!-- <router-link :to="javascript:void(0)" @click="filtering(category)">
                        <img :srcset="category.image_url"  :alt="category.name" :title="category.name">
                    </router-link> -->
                    <h3 class="ol-home-category__list-item-title">
                        <!-- <router-link :to="'/products/category_group='+category.id">{{category.name}}</router-link> -->
                        <!-- <router-link :to="'/products/category_group='+category.id" @click.native="scrollToTop()">{{category.name}}</router-link> -->
                        <router-link :to="'/category/'+category.name.toLowerCase().replaceAll(' ','-')" @click.native="scrollToTop()">{{category.name}}</router-link>
                    </h3>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import helpme from '@/util/helpme'
import SkeletonLoader from "@/components/loading/skeletonLoader";
export default {
    name:"Categories",
    props:["categories"],
    components:{
        SkeletonLoader,
    },
    data() {
        return {
            loading:true,
            loadingItem: 2,
        };
    },
    methods:{
        // filtering(category){
		// 	this.$store.commit("product/list/resetFilter",true);
		// 	this.$store.commit("product/list/setFiltered",{category_group:category.id});
		// 	this.$store.commit("product/list/setCategoryGroup",category);
		// 	if(this.$route.name != "Products") this.$router.push('/products');
		// },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    mounted(){
        if(this.categories.length > 0) this.loading = false;
    },
    watch:{
        "categories":function(value){
            this.loading = false;
        }
    }
}
</script>

<style>

</style>