<template>
    <div>
        <div v-if="message" v-show="shown_message" :class="'ol-alert ol-alert--'+type">
            <span  style="padding-left:15px;"> 
                <i class="fa fa-info-circle"></i> {{message}}
            </span>
            <button type="button" style="padding-right:15px;float:right" @click="close(1)" class="close" data-dismiss="alert">×</button>
        </div>
        <div v-if="collection" v-show="shown_collection" :class="'ol-alert ol-alert--'+type">
            <button type="button" style="padding-right:15px;float:right" @click="close(2)" class="close" data-dismiss="alert">×</button>

            <span  style="padding-left:15px;" v-for="(columns, i) in collection" :key="i">
                <span v-for="(message,j) in columns" :key="j"> 
                    <i class="fa fa-info-circle"></i> {{message}}<br>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message','collection','type'],
    name:"alert",
    data(){
        return {
            shown_message:true,
            shown_collection:true,
        }
    },
    watch:{
        message:function(value){
            this.shown_message = true;
        },
        collection:function(value){
            this.shown_collection = true;
        }
    },
    methods:{
        close(alert){
            if(alert==1) this.shown_message = false;
            if(alert==2) this.shown_collection = false;
        },
    },
    created(){
            this.shown_message = true;

    }
}
</script>