<template>
<div :class="wrapclass ? wrapclass:'ol-product-content__center'">
    <div v-if="page==1" class="ol-product-content__center-filter add-fix">
        <SkeletonLoader style="height:30px" />
    </div>
    <div class="ol-product-content__center-list">
        <div class="ol-home-product__content-item-list--item" style="height:320px" v-for="i in qty" :key="i">
            <figure class="ol-home-product__content-item-list--item-figure" style="height:70%;margin:5px 0px 5px 0px">
                <SkeletonLoader/>
            </figure>
            <h3 class="ol-home-product__content-item-list--item-title" style="height:15%;margin:5px 0px 5px 0px">
                <SkeletonLoader corner="5"/>
            </h3>        
            <div class="ol-home-product__content-item-list--item-title" style="height:10%;margin:5px 0px 5px 0px">
                <SkeletonLoader corner="5" />
            </div>
        </div>
        
    </div>
</div>
</template>

<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"Content",
    props:['page','qty','wrapclass'],
    components:{
        SkeletonLoader
    },

}
</script>
