import apiurl from '@/util/apiurl'
import helpme from '@/util/helpme'
import _ from 'lodash';
import Vue from 'vue';
const CHECKOUT = apiurl.WS_TRANSACTION.CHECKOUT;
const HOME = apiurl.WS_HOME;

export default {
    namespaced: true,
    state: {
        shipping: null,
        payment: !JSON.parse(localStorage.getItem("payment")) ? null : JSON.parse(localStorage.getItem("payment")),
        dropship: !JSON.parse(localStorage.getItem("payment")) ? null : JSON.parse(localStorage.getItem("dropship")),
        order: !JSON.parse(localStorage.getItem("order")) ? null : JSON.parse(localStorage.getItem("order")),
        items: !JSON.parse(localStorage.getItem("items")) ? null : JSON.parse(localStorage.getItem("items")),
        payments: [],
        paymentList: !JSON.parse(localStorage.getItem("paymentList")) ? null : JSON.parse(localStorage.getItem("paymentList")),
        voucher: null,
        summary: null,
        tenor: !JSON.parse(localStorage.getItem("tenor")) ? null : JSON.parse(localStorage.getItem("tenor")),
        fee: !JSON.parse(localStorage.getItem("fee")) ? null : JSON.parse(localStorage.getItem("fee")),
    },
    getters: {
        shipping: (state) => state.shipping,
        payment: (state) => state.payment,
        payments: (state) => state.payments,
        paymentList: (state) => state.paymentList,
        summary: (state) => state.summary,
        voucher: (state) => state.voucher,
        order: (state) => state.order,
        items: (state) => state.items,
        dropship: (state) => state.dropship,
        tenor: (state) => state.tenor,
        fee: (state) => state.fee,
    },
    mutations: {
        setShipping: (state, payload) => {
            state.shipping = payload;
            localStorage.setItem("shipping", JSON.stringify(state.shipping));
        },
        setPayment: (state, payload) => {
            state.payment = payload;
            localStorage.setItem("payment", JSON.stringify(state.payment));
        },
        setOrder: (state, payload) => {
            state.order = payload;
            localStorage.setItem("order", JSON.stringify(state.order));
        },
        setDropship: (state, payload) => {
            state.dropship = payload;
            localStorage.setItem("dropship", JSON.stringify(state.dropship));
        },
        setPayments: (state, payload) => (state.payments = payload),
        setPaymentList: (state, payload) => {
            state.paymentList = payload;
            localStorage.setItem("paymentList", JSON.stringify(state.paymentList));
        },
        setVoucher: (state, payload) => (state.voucher = payload),
        setSummary: (state, payload) => (state.summary = payload),
        setSummaryWithDiscount: (state, discountAmount) => {
            state.summary.summary.discount_amount = discountAmount;
            state.summary.summary.discount_amount_rp = helpme.toRp(discountAmount);
            state.summary.summary.bill_total = state.summary.summary.grand_total - state.summary.summary.discount_amount;
            state.summary.summary.bill_total_rp = helpme.toRp(state.summary.summary.bill_total);
        },
        setItems: (state, payload) => {
            state.items = payload;
            localStorage.setItem("items", JSON.stringify(state.items));
        },
        setTenor: (state, payload) => {
            state.tenor = payload;
            localStorage.setItem("tenor", JSON.stringify(state.tenor));
        },
        setFee: (state, payload) => {
            state.fee = payload;
            localStorage.setItem("fee", JSON.stringify(state.fee));
        },
    },
    actions: {
        async fetchPayments({ commit, state }) {
            let result = { success: false };
            if (_.isEmpty(state.payments)) {
                await CHECKOUT.PAYMENTS().then(response => {
                    result = response.data;
                    localStorage.setItem("type_device", "Browser");
                    commit("setPayments", result.data);
                }).catch(error => {
                    // console.error("fetchPayments", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchPaymentList({ commit, state }) {
            let result = { success: false };
            if (_.isEmpty(state.payments)) {
                await CHECKOUT.PAYMENT_LIST().then(response => {
                    result = response.data;
                    commit("setPaymentList", result.data);
                }).catch(error => {
                    // console.error("fetchPayments", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchSummary({ commit, state }, payload) {
            let result = { success: false, data: {} };
            await CHECKOUT.PRE_CHECKOUT_SUMMARY(payload).then(response => {
                result = response.data;
                localStorage.setItem("type_device", "Browser");
                commit("setSummary", result.data);
            }).catch(error => {
                // console.error("preCheckOutCheck", error);
                result = error.response.data;
            });
            return result;
        },
        async saveTransaction({ commit, state }, payload) {
            let result = { success: false, data: {} };
            await CHECKOUT.SAVE_TRANSACTION(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("saveTransaction", error);
                result = error.response.data;
            });
            if (result.success) commit("setOrder", result.data);
            if (result.statusCode == 403) commit("setSummary", result.data);

            return result;
        },
        async getIpPublic() {
            let ip = "";
            await HOME.GET_IP().then(response => {
                ip = response.data.ip;
            }).catch(error => {});
            return ip;
        },
    },
}