import apiurl from '@/util/apiurl';
const URL = apiurl.WS_NOTIFICATION;

export default {
    namespaced:true,
    state:{
        data:[],
        unread:0,
        laravelPagenation:null
    },
    getters:{
        data: (state) => _.take(state.data,3),
        unread: (state) => state.unread,
        laravelPagenation: (state) => state.laravelPagenation
    },
    mutations: {
        setData: (state, data) => (state.data = data),
        setUnread: (state,unread) => (state.unread = unread),
        setLaravelPagenation: (state,laravelPagenation) => (state.laravelPagenation = laravelPagenation)
    },
    actions:{
        async fetchData({ commit, state }, data) {
            let result = { success: false };
            await URL.GET_LIST(data).then(response => {
                result = response.data;
                // console.log(result);
                let notif = result.data.notif;
                let unread = result.data.unread;
                commit("setLaravelPagenation",notif)
                commit("setUnread", unread);
                if(notif.current_page == 1)
                commit("setData", notif.data);
                // console.log(state.data);
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
        async fetchView({ commit, state }, data) {
            let result = { success: false };
            await URL.SET_VIEW(data).then(response => {
                result = response.data;
                // console.log(result);
            }).catch(error => {
                // console.error("fetchData", error);
                result = error.response.data;
            });
            return result;
        },
        async sendReminder({ commit, state }, payload) {
            let result = { success: false, data: {} };
            
            await URL.SET_REMINDER(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("checkStock", error);
                result = error.response.data;
            });

            return result;
        },
        async logout({ commit }) {
            commit("setData", null);
            commit("setUnread", 0);
            commit("setLaravelPagenation", null);
        },
    }

}