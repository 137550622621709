<template>
    <div class="ol-home-type-product__list-item">
        <div class="ol-home-type-product__list-item-wrapper">
            <div class="ol-home-type-product__list-item-title" style="height:30px">
                <SkeletonLoader/>
            </div>
            <div class="ol-home-type-product__list-item-box">
                <div class="ol-home-type-product__list-item-loop">
                    <figure class="ol-home-type-product__list-item-loop-figure" style="height:150px">
                        <SkeletonLoader/>
                    </figure>
                    <div class="ol-home-type-product__list-item-loop-desc">
                        <h3 class="ol-home-type-product__list-item-loop-title" style="height:60px">
                            <SkeletonLoader/>
                        </h3>
                        <div class="ol-home-product__content-item-list--item-price" style="height:50px">
                            <SkeletonLoader/>
                        </div>
                    </div>
                </div>
                <div class="ol-home-type-product__list-item-loop">
                    <figure class="ol-home-type-product__list-item-loop-figure" style="height:150px">
                        <SkeletonLoader/>
                    </figure>
                    <div class="ol-home-type-product__list-item-loop-desc">
                        <h3 class="ol-home-type-product__list-item-loop-title" style="height:60px">
                            <SkeletonLoader/>
                        </h3>
                        <div class="ol-home-product__content-item-list--item-price" style="height:50px">
                            <SkeletonLoader/>
                        </div>
                    </div>
                </div>
                <div class="ol-home-type-product__list-item-loop">
                    <figure class="ol-home-type-product__list-item-loop-figure" style="height:150px">
                        <SkeletonLoader/>
                    </figure>
                    <div class="ol-home-type-product__list-item-loop-desc">
                        <h3 class="ol-home-type-product__list-item-loop-title" style="height:60px">
                            <SkeletonLoader/>
                        </h3>
                        <div class="ol-home-product__content-item-list--item-price" style="height:50px">
                            <SkeletonLoader/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"Top",
    props:['qty'],
    components:{
        SkeletonLoader
    },

}
</script>
