import apiurl from '@/util/apiurl'
const URL = apiurl.WS_PROFILE.DROPSHIPPER;
const UNI = apiurl.WS_UNI;
export default {
    namespaced: true,
    state: {
        dropshippers:[],
    },
    getters: {
        dropshippers: (state) => state.dropshippers,
    },
    mutations: {
        setDropshippers: (state, dropshippers) => ( state.dropshippers = _.orderBy(dropshippers, ['created_at'], ['desc','asc']) ),
    },
    actions: {
        async fetchDropshippers({ commit, state }) {
            let result = true;
            if (_.isEmpty(state.dropshippers)) {
                await URL.GET().then(response => {
                    result = response.data;
                    commit("setDropshippers", result.data);
                }).catch(error => {
                    result = false;
                    // console.log("fetchDropshippers", error.response.data);
                });
            }
            return result;
        },
        async saveDropshipper({ commit, state }, input) {
            let result = null;
            await URL.STORE(input).then(response => {
                result = response.data;
                let dropshipperList = state.dropshippers;
                dropshipperList.push(result.data);
                commit("setAddresses", dropshipperList);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async updateDropshipper({ commit, state }, input) {
            let result = null;
            await URL.UPDATE(input).then(response => {
                result = response.data;
                let dropshipperList = state.dropshippers;
                dropshipperList[_.findIndex(dropshipperList, { "id": result.data.id })] = result.data;
                commit("setDropshippers", dropshipperList);
            }).catch(error => {
                result = error.response.data;
            });
            return result;
        },
        async delDropshipper({ commit, state }, id) {
            let result = null;
            await URL.DELETE({ id:id }).then(response => {
                result = response.data;
                commit("setDropshippers", _.filter(state.dropshippers, (item) => item.id != result.data.id));
            }).catch(error => {
                result = error.response.data;
                // console.log("delDropshipper", result);
            });
            return result;
        },
    }
};