<template>
    <div>
        <CategoriesHeaderLoader v-if="loading" />
        <div v-else class="ol-header-category-content" style="background-color: white;">
            <div class="ol-wrapper ol-wrapper--large" id="ol-header-fix">
                <ul class="ol-header-category__nav" v-if="categories.length">
                    <li>
                        <a href="javascript:void(0)"  @click="allProduct()">Semua Produk</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)"  @click="bundling()">Paket Hemat</a>
                    </li>
                    <li>
                        <router-link to="/moreverse">MOREVERSE</router-link>
                    </li>
                    <li>
                        <router-link to="/konsultasi">Layanan Desain Interior</router-link>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="menuClicked" class="header-menu-ruangan">Ruangan</a>
                        <ul  class="ol-header-category__nav-sub add-fix header-sub-menu-ruangan" style="display:none">
                            <li v-for="(category) in categories" :key="category.id">
                                <h4>
                                    <p>{{category.name}}</p>
                                </h4>
                                <a class="sub-menu-room" href="javascript:void(0)" v-for="(item2) in category.r_subs" :key="item2.id" @click="filtering(item2)">{{ item2.name}}</a>
                                <!-- <router-link :to="'/products/categories%5B%5D='+item2.id" class="sub-menu-room" v-for="(item2) in category.r_subs" :key="item2.id" >{{ item2.name}}</router-link> -->
                            </li>
                        </ul>
                    </li>
                    <!-- <li>
                        <router-link to="/artikel">Artikel</router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import CategoriesHeaderLoader from "@/components/loading/CategoriesHeaderLoader";
export default {
    name:"CategoriesHeader",
    props:["categories"],
    components:{
        CategoriesHeaderLoader,
    },
    data() {
        return {
            loading:true,
            loadingItem: 12,
        };
    },
	methods:{

        menuClicked:(event)=>{
            if(event.srcElement.classList.contains('active')){
                event.srcElement.classList.remove('active');
                event.target.nextSibling.style.setProperty('display','none');
                event.target.parentElement.parentElement.parentElement.parentElement.classList.remove('show');
            }else{
                $(".ol-header-category__nav > li > a").removeClass("active");
                $(".ol-header-category__nav-sub").hide();
                event.srcElement.classList.add('active');
                event.target.nextSibling.style.setProperty('display','flex');
                event.target.parentElement.parentElement.parentElement.parentElement.classList.add('show');
            }
        },
        filtering(subCategory){

            $(".header-menu-ruangan").trigger("click");
            // console.log(subCategory);
                this.$store.commit("product/list/resetFilter",true);
                this.$store.commit("product/list/setFiltered",{
                    categories:[subCategory.id],
                });
                if(this.$route.name != "Products") this.$router.push('/products');

        },
        allProduct(){
            this.$store.commit("product/list/resetFilter",true);
            if(this.$route.name != "Products") this.$router.push('/products');
        },
        bundling(){
            this.$store.commit("product/list/resetFilter",true);
            this.$store.commit("product/list/setFiltered",{
                category_group:[14],
            });
            if(this.$route.name != "Products") this.$router.push('/products');
        }

        // filtering(category,LEVEL2_INDEX,LEVEL3_INDEX=0){
        //     console.log(category);

        //     // document.getElementById("a-ctg-header-"+category.id).click();

        //     let categoryGroup = category;
        //     categoryGroup['r_sub_active'] = category.r_subs[LEVEL2_INDEX];
        //     categoryGroup['r_sub_active']['r_sub_active'] = categoryGroup['r_sub_active'].r_subs[LEVEL3_INDEX];

        //     this.$store.commit("product/list/setCategoryGroup",categoryGroup);
            
		// 	if(this.$route.name != "Products") {
		// 	    this.$store.commit("product/list/resetFilter",true);
        //         this.$store.commit("product/list/setFiltered",{
        //             category_group:categoryGroup.id,
        //             categories:[categoryGroup['r_sub_active'].id,categoryGroup['r_sub_active']['r_sub_active'].id],
        //         });
        //         this.$router.push('/products')
        //     }else{
        //         this.$store.commit("product/list/setFiltered",{
        //             category_group:categoryGroup.id,
        //             categories:[categoryGroup['r_sub_active'].id,categoryGroup['r_sub_active']['r_sub_active'].id],
        //         });
        //     }
		// },
    },
    mounted(){
        if(this.categories.length > 0) this.loading = false;
    },
    watch:{
        "categories":function(value){
            if(value.length > 0) this.loading = false;
        }
    }
}
</script>

<style>

</style>