import apiurl from '@/util/apiurl';
import * as moment from 'moment'
const HOME = apiurl.WS_HOME;
const PRODUCT = apiurl.WS_PRODUCT;
const CART = apiurl.WS_TRANSACTION.CART;
export default {
    namespaced: true,
    state: {
        content: null,
        banners:[],
        vouchers: [],
        frees: [],
        flashsale_groups: [],
        combos: [],
    },
    getters: {
        content: (state) => state.content,
        banners: (state) => state.banners,
        vouchers: (state) => state.vouchers,
        frees: (state) => state.frees,
        flashsale_groups: (state) => state.flashsale_groups,
        combos: (state) => state.combos,
    },
    mutations: {
        setContent: (state, payload) => (state.content = payload),
        setBanners: (state, payload) => (state.banners = payload),
        setVouchers: (state, payload) => (state.vouchers = payload),
        setFrees: (state, payload) => (state.frees = payload),
        setFlashsaleGroups: (state, payload) => (state.flashsale_groups = payload),
        setCombos: (state, payload) => (state.combos = payload),
    },
    actions: {
        async fetchContent({state, commit }, slug) {
            let result = { success: false };
            if (_.isEmpty(state.content)) {
                await PRODUCT.GROUP(slug).then(response => {
                    result = response.data;
                    commit("setContent", result.data);
                    // console.log(result.data);
                    if (result.data) {
                        if (state.content.group.image_desktop  && state.content.group.image_mobile ) {
                            commit("setBanners", [{
                                web: state.content.group.image_desktop_url,
                                mobile: state.content.group.image_mobile_url,
                            }]);
                        }
                    }
                }).catch(error => {
                    // console.error("fetchContent", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchVouchers({state, commit }) {
            let result = { success: false };
            if (_.isEmpty(state.vouchers)) {
                await CART.GET_VOUCHER().then(response => {
                    result = response.data;
                    commit("setVouchers", result.data);
                }).catch(error => {
                    // console.error("fetchVouchers", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchFrees({state, commit}) {
            let result = { success: false };
            if (_.isEmpty(state.frees)) {
                await HOME.GET_FREE().then(response => {
                    result = response.data;
                    commit("setFrees", result.data);
                }).catch(error => {
                    // console.error("fetchVouchers", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchFlashsaleGroup({state, commit}) {
            let result = { success: false };
            if (_.isEmpty(state.flashsale_groups)) {
                await HOME.GET_FLASHSALE_GROUP().then(response => {
                    result = response.data;
                    commit("setFlashsaleGroups", result.data);
                }).catch(error => {
                    // console.error("fetchVouchers", error);
                    result = error.response.data;
                });
            }
            return result;
        },
        async fetchCombos({state, commit}) {
            let result = { success: false };
            if (_.isEmpty(state.flashsale_groups)) {
                await HOME.GET_PRODUCT_COMBO().then(response => {
                    result = response.data;
                    commit("setCombos", result.data);
                }).catch(error => {
                    // console.error("fetchVouchers", error);
                    result = error.response.data;
                });
            }
            return result;
        },
    },
}